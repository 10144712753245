import { useMemo } from 'react'
import { BorrowPosition, useOpenBorrowPositions } from '../types/borrowPositionData'
import JSBI from 'jsbi'
import { useActiveWeb3React } from './index'
import { STRATEGY_INDEX_LOWER, STRATEGY_INDEX_UPPER, useStrategyData } from '../pages/Strategies/StrategiesList'
import { Transfer, useTransfersByWalletAddress } from '../types/transferData'

export interface ActivePosition {
  position: BorrowPosition
  strategy: number
}

export const isActiveStrategy = (position: BorrowPosition) => {
  const positionId = JSBI.toNumber(position.marginAccount.accountNumber)
  return (
    positionId >= STRATEGY_INDEX_LOWER &&
    positionId <= STRATEGY_INDEX_UPPER &&
    position.status === 'OPEN' &&
    position.supplyAmounts.length > 0
  )
}

export function useActiveStrategies(positions: BorrowPosition[]): [ActivePosition[], Transfer[]] {
  const { account, chainId } = useActiveWeb3React()
  const { data: allTransfers } = useTransfersByWalletAddress(account)
  const strategiesWithAmounts = useStrategyData()
  const filteredPositions = useMemo(() => positions?.filter(position => isActiveStrategy(position)), [positions])
  const positionIds = useMemo(() => new Set(filteredPositions.map(position => position.id)), [filteredPositions])
  const filteredTransfers = useMemo(() => {
    return allTransfers?.filter(transfer => {
      return positionIds.has(transfer.toMarginAccount.toString())
    })
  }, [allTransfers, positionIds])
  return useMemo(() => {
    return [
      filteredPositions?.reduce((filteredPositions: ActivePosition[], position: BorrowPosition) => {
        const positionTransfers = filteredTransfers
          ?.filter(transfer => transfer.toMarginAccount.toString() === position.id)
          .sort((a, b) =>
            position.specialInfo.isolationModeVaultAddress
              ? JSBI.toNumber(a.serialId) - JSBI.toNumber(b.serialId)
              : JSBI.toNumber(b.serialId) - JSBI.toNumber(a.serialId),
          )
        if (positionTransfers.length < 2 || positionTransfers[0].token.symbol !== positionTransfers[1].token.symbol) {
          return filteredPositions
        }
        const initialDeposit = positionTransfers[0]
        const loop = positionTransfers[1]
        // Divides the initial deposit + looped amount by initial deposit to get a leverage ratio, then rounds to the
        // nearest whole number to determine which strategy it matches
        const calculatedLeverage = Math.round(
          parseFloat(
            initialDeposit.amount.asFraction
              .add(loop.amount.asFraction)
              .divide(initialDeposit.amount.asFraction)
              .toFixed(2),
          ),
        )
        const leverage = calculatedLeverage === 1 ? 4 : calculatedLeverage
        const matchedStrategy = strategiesWithAmounts.find(
          strategy =>
            strategy.leverage === leverage &&
            strategy.chain === chainId &&
            strategy.collateralAssets[0].address === initialDeposit.token.address &&
            strategy.debtAssets[0].address === position.borrowAmounts[0]?.token.address,
        )
        if (matchedStrategy) {
          filteredPositions.push({
            position: position,
            strategy: parseInt(matchedStrategy.id) - 1,
          })
        }
        return filteredPositions
      }, []),
      filteredTransfers,
    ]
  }, [chainId, strategiesWithAmounts, filteredPositions, filteredTransfers])
}
