import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { useActiveWeb3React } from '../../hooks'
import Leaderboard from './Leaderboard'
import { StyledTooltip, StyledTooltipWithIcon } from '../../components/common/StyledTooltip'
import { LiquidityMiningClaim, useAllLiquidityMiningClaims } from '../../types/liquidityMiningClaimData'
import { BIG_INT_ZERO, ChainId, ONE_FRACTION, ZERO_FRACTION } from '../../constants'
import {
  useAggregateBalanceDataForMinerals,
  useClaimLiquidityMiningRewards,
  useExternalMinerals,
  useMineralMultiplierInfo,
  useMineralTokenBalance,
  useProjectedMineralsThisWeek,
  useTokensWithMineralRewards,
  useTokenToMineralsPerWeekMap,
} from '../../hooks/useLiquidityMining'
import { useMineralToken } from '../../hooks/Tokens'
import { CurrencyAmount, Token } from '@dolomite-exchange/v2-sdk'
import { formatAmount } from '../../utils/formatAmount'
import PageTitle from '../../components/common/PageTitle'
import { useLiquidityMiningLeaderboardRankByAccount } from '../../types/liquidityMiningLeaderboard'
import { useTranslation } from 'react-i18next'
import useApprovedToS from '../../hooks/useApprovedToS'
import FirstVisitPopoverMinerals from '../../components/FirstVisitPopoverMinerals/FirstVisitPopoverMinerals'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useIsTransactionPending } from '../../state/transactions/hooks'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import { NETWORK_LABELS } from '../../constants/chainId'
import Sparkle from 'react-sparkle'
import { ExternalLink } from '../../theme'

const MineralsWrapper = styled.div`
  margin: 25px auto;
  width: 80vw;
  max-width: 900px;

  svg {
    transform: translate(-3px, 1px);
  }

  @media screen and (max-width: 1219px) {
    width: 90vw;
  }

  @media screen and (max-width: 1000px) {
    width: calc(100% - 40px);
    margin: 5px auto;
  }

  @media screen and (max-width: 580px) {
    margin: 0 auto 15px;
    width: calc(100vw - 32px);
  }
`

const TopSection = styled.div<{ disabled?: boolean }>`
  ${({ disabled }) => disabled && 'pointer-events: none;'}
  ${({ disabled }) => disabled && 'opacity: 0.5;'}
`

const Title = styled.div`
  width: 100%;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 0;

  @media screen and (max-width: 500px) {
    font-size: 28px;
  }
`

const Descriptor = styled.div`
  width: 100%;
  font-size: 15px;
  font-weight: 300;
  color: #d5d6e1;
  margin-bottom: 15px;
  line-height: 22px;

  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
`

const PanelRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`

const Panel = styled.div`
  width: calc(20% - 10px);
  padding: 24px 32px 26px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  border-radius: 5px;
  background-color: ${({ theme }) => theme.bg1};
  position: relative;

  @media screen and (max-width: 900px) {
    height: fit-content;
  }
`

const PanelTitle = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.text2};
  font-weight: 400;

  @media screen and (max-width: 900px) {
    font-size: 13px;
  }

  @media screen and (max-width: 500px) {
    font-size: 12px;
  }
`

const PanelSubtitle = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.text2};
  font-weight: 300;
  margin-top: 2px;
  margin-bottom: 15px;

  @media screen and (max-width: 900px) {
    font-size: 9px;
  }

  @media screen and (max-width: 500px) {
    font-size: 8px;
  }
`

const PanelValue = styled.div<{ isAtRisk?: boolean; isBoosted?: boolean; adjustForLength?: boolean }>`
  font-weight: 600;
  color: ${({ theme, isAtRisk, isBoosted }) => (isAtRisk ? theme.red1 : isBoosted ? theme.blue2 : theme.text1)};
  font-size: 52px;
  ${({ adjustForLength }) => adjustForLength && 'margin-left: -10px !important;'}
  position: relative;

  @media screen and (max-width: 900px) {
    font-size: 45px;
  }

  @media screen and (max-width: 500px) {
    font-size: 38px;
  }
`

const MultiplierBoostWrapper = styled.div`
  position: absolute;
  top: 38px;
  left: 32px;
  font-weight: 600;
  font-size: 11px;
  color: ${({ theme }) => theme.blue2};
`

const MultiplierRiskDescription = styled.div`
  font-size: 9px;
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-bottom: -13.5px;
  margin-top: -10px;
  text-align: center;
  font-weight: 400;

  @media screen and (max-width: 900px) {
    margin-bottom: -3.5px;
  }
`

const Thin = styled.span`
  font-weight: 300;
  font-size: 44px;

  @media screen and (max-width: 900px) {
    font-size: 38px;
  }

  @media screen and (max-width: 500px) {
    font-size: 32px;
  }
`

const PanelBonusRow = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.text2};
  font-weight: 300;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 900px) {
    font-size: 10px;
    margin-right: -20px;
  }

  @media screen and (max-width: 500px) {
    font-size: 9px;
  }
`

const PanelColumn = styled.div<{ numPanels: number }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${({ numPanels }) => (numPanels === 4 ? '0' : '10px')};

  ${({ numPanels }) => (numPanels >= 4 ? 'flex-wrap: wrap;' : '')}
  ${Panel} {
    width: ${({ numPanels }) => (numPanels === 4 ? 'calc(50% - 5px)' : 'calc(33% - 5px)')} !important;

    ${({ numPanels }) => (numPanels === 4 ? 'margin: 0 0 10px 0;' : '')}
    :nth-of-type(1),
        :nth-of-type(2) {
      ${({ numPanels }) => (numPanels === 5 ? 'width: calc(50% - 5px) !important' : '')};
      ${({ numPanels }) => (numPanels === 5 ? 'margin-bottom: 10px !important' : '')};
    }
  }

  ${PanelTitle} {
    svg {
      transform: translate(-4px, 0px);
    }
  }

  @media screen and (max-width: 700px) {
    width: 100%;
    ${({ numPanels }) => !(numPanels === 4) && 'display: block;'}
    ${Panel} {
      ${({ numPanels }) =>
        !(numPanels === 4) &&
        `width: calc(50% - 5px) !important;
        margin: 10px 0 0 0;
        display: inline-block;
        `}

      ${({ numPanels }) =>
        !(numPanels === 4) &&
        `:nth-of-type(1) {
          width: 100% !important;
          margin: 0;
        }
        
        :nth-of-type(1),
        :nth-of-type(2) {
          margin-bottom: 0 !important;
        }
  
        :nth-of-type(3) {
          margin: 0 0 0 10px;
        }
        
        :nth-of-type(5) {
          margin: 0 0 0 10px;
        }`}
    }
  }

  @media screen and (max-width: 500px) {
    margin-top: 10px;
    flex-direction: column;
    ${({ numPanels }) => numPanels === 4 && 'margin-bottom: 10px;'}
    ${({ numPanels }) =>
      numPanels === 4 &&
      `> div:nth-child(1),
      > div:nth-child(4) {
        margin: 10px 0 0 0;
      }`}
        > div:nth-child(2),
        > div:nth-child(3) {
      margin: 10px 0 0 0;
    }

    ${Panel} {
      width: 100% !important;

      :nth-of-type(1),
      :nth-of-type(2),
      :nth-of-type(3),
      :nth-of-type(5) {
        width: 100% !important;
        margin: 10px 0 0 0 !important;
      }
    }
  }
`

const TopRow = styled(PanelRow)`
  @media screen and (max-width: 700px) {
    display: block;

    > div {
      display: inline-block;
      vertical-align: top;
    }
  }

  ${Panel} {
    width: calc(24% - 10px);
    padding: 24px 32px 22px;

    :nth-of-type(3) {
      width: calc(20% - 10px);
    }

    @media screen and (max-width: 900px) {
      padding-bottom: 19px;
    }

    @media screen and (max-width: 825px) {
      width: calc(22% - 10px);

      :nth-of-type(3) {
        width: calc(22% - 10px);
      }
    }

    @media screen and (max-width: 800px) {
      width: calc(50% - 180px);

      :nth-of-type(3) {
        width: calc(50% - 180px);
      }
    }

    @media screen and (max-width: 700px) {
      width: calc(50% - 5px);

      :nth-of-type(1) {
        margin-bottom: 10px;

        :nth-of-type(1) {
          margin-bottom: 10px !important;
        }
      }

      :nth-of-type(3) {
        margin-left: 10px;
        width: calc(50% - 5px);

        > div > div {
          text-align: left;
          margin-left: 0;
        }
      }

      /*:nth-of-type(3) {
                                                                                                                                      width: calc(30% - 10px);
                                                                                                                                      display: inline-block;
                                                                                                                                      margin: 147px 0 -146px -30%;
                                                                                                                                      padding-bottom: 23px;
                                                                                                                                    }*/
    }

    @media screen and (max-width: 650px) {
      width: calc(50% - 5px);
      margin: 0;

      :nth-of-type(1) {
        display: inline-block;
        width: 100% !important;
      }

      :nth-of-type(3) {
        margin: 0 0 0 10px;
      }
    }

    @media screen and (max-width: 500px) {
      display: inline-block;
      margin: 0 !important;

      :nth-of-type(1) {
        margin-bottom: 10px !important;
      }

      :nth-of-type(2) {
        width: calc(50% - 5px);
        display: inline-block;
      }

      :nth-of-type(3) {
        width: calc(50% - 5px);
        margin: 0 0 0 10px !important;
        display: inline-block;
      }
    }

    @media screen and (max-width: 450px) {
      margin: 0 !important;

      :nth-of-type(1) {
        margin-bottom: 10px !important;
      }

      :nth-of-type(2) {
        width: calc(50% - 5px);
      }

      :nth-of-type(3) {
        width: calc(50% - 5px);
        margin: 0 0 0 10px !important;
      }
    }
  }

  ${PanelValue} {
    font-size: 52px;

    @media screen and (max-width: 900px) {
      font-size: 45px;
    }

    @media screen and (max-width: 500px) {
      font-size: 42px;
    }

    @media screen and (max-width: 450px) {
      font-size: 40px;
    }
  }
`

const SecondRow = styled(PanelRow)`
  flex-direction: column;

  ${Panel} {
    width: calc(30% - 10px);
  }

  ${PanelTitle} {
    font-size: 21px;
    margin-bottom: -2px;

    @media screen and (max-width: 900px) {
      font-size: 18px;
    }

    @media screen and (max-width: 500px) {
      font-size: 16px;
    }
  }

  ${PanelValue} {
    font-size: 32px;
    margin-bottom: 2px;

    @media screen and (max-width: 900px) {
      font-size: 28px;
    }

    @media screen and (max-width: 500px) {
      font-size: 24px;
    }
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`

const ExternalMineralsPanel = styled(Panel)`
  width: 100% !important;
  height: auto;
  margin-bottom: 10px;
`

const ExternalMinealsRow = styled.div`
  width: 100%;
  margin: 5px 0;

  svg {
    transform: translate(-3px, 2px);
  }
`

const ExternalMineralsRowTop = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: 600;
`

const ExternalMineralsRowBottom = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.text2};
  font-size: 12px;
  font-weight: 400;
`

const ExternalMineralsTotalTitle = styled.div``

const ExternalMultiplier = styled.span`
  font-weight: 800;
  color: ${({ theme }) => theme.blue2};
`

const ExternalMineralsTitle = styled.div`
  a {
    color: ${({ theme }) => theme.text1};
    font-weight: 600;
    text-decoration: none;

    :hover {
      opacity: 0.7;
    }
  }
`

const ExternalMineralsAmount = styled.div``

const ExternalMineralsTotalAmount = styled.div``

const EarnPanel = styled(Panel)<{ showExternal: boolean }>`
  width: 100% !important;
  height: ${({ showExternal }) => (showExternal ? '308' : '284')}px;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 900px) {
    height: ${({ showExternal }) => (showExternal ? '286' : '262')}px;
  }

  @media screen and (max-width: 800px) {
    width: 100% !important;
  }

  @media screen and (max-width: 465px) {
    height: ${({ showExternal }) => (showExternal ? '414' : '390')}px;
  }
`

const EarnedThisWeekWrapper = styled.div`
  position: absolute;
  top: 24px;
  left: 32px;

  ${PanelTitle} {
    font-size: 14px;
    margin-bottom: -2px;

    @media screen and (max-width: 900px) {
      font-size: 13px;
    }

    @media screen and (max-width: 500px) {
      font-size: 12px;
    }
  }

  ${PanelValue} {
    font-size: 48px;
    color: ${({ theme }) => theme.green1};

    @media screen and (max-width: 900px) {
      font-size: 40px;
    }

    @media screen and (max-width: 500px) {
      font-size: 35px;
    }
  }
`

const DataRowsWrapper = styled.div`
  position: absolute;
  width: calc(100% - 64px);
  top: 112px;
  left: 32px;

  @media screen and (max-width: 900px) {
    top: 106px;
  }

  @media screen and (max-width: 465px) {
    top: 140px;
  }

  @media screen and (max-width: 400px) {
    top: 150px;
  }
`

const EarnLabel = styled.div<{ warn?: boolean }>`
  font-weight: 300;
  color: ${({ theme, warn }) => (warn ? theme.yellow1 : theme.text1)};
  font-size: 14px;

  @media screen and (max-width: 465px) {
    width: 185px;
    line-height: 11px;
    font-size: 11px;
  }
`

const EarnValue = styled.div<{ isAtRisk?: boolean }>`
  font-weight: 600;
  color: ${({ theme, isAtRisk }) => (isAtRisk ? theme.red1 : theme.text1)};
  font-size: 14px;

  @media screen and (max-width: 465px) {
    font-size: 16px;
  }
`

const EarnDataRow = styled.div`
  width: 100%;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;

  @media screen and (max-width: 900px) {
    font-size: 12px;
  }

  @media screen and (max-width: 500px) {
    font-size: 11px;
  }

  @media screen and (max-width: 465px) {
    :nth-of-type(2) {
      margin-bottom: 9px;
    }

    ${EarnValue} {
      margin-top: 4px;
    }

    :nth-of-type(1) {
      ${EarnValue} {
        margin-top: 0;
      }
    }
  }
`

const MultiplierColor = styled.span<{ isAtRisk: boolean }>`
  font-weight: 600;
  color: ${({ theme, isAtRisk }) => (isAtRisk ? theme.red1 : theme.green1)};
`

const MineralsTotal = styled(Panel)`
  font-weight: 600;
  color: ${({ theme }) => theme.blue2};
  width: calc(56% - 10px) !important;

  ${PanelValue} {
    color: ${({ theme }) => theme.blue2};
  }

  @media screen and (max-width: 800px) {
    width: 340px !important;
  }

  @media screen and (max-width: 700px) {
    width: 100% !important;
    margin: 0 !important;
  }

  @media screen and (max-width: 500px) {
    margin-bottom: 10px !important;
  }
`

const RankSuperscript = styled.div`
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
  margin-top: 10px;

  @media screen and (max-width: 900px) {
    font-size: 14px;
  }

  @media screen and (max-width: 500px) {
    font-size: 12px;
  }
`

const Rank69 = styled.span`
  background-image: linear-gradient(to left, violet, green, yellow, orange, red);
  background-clip: text;
  color: transparent;
`

const ExplainerWrapper = styled.div`
  position: absolute;
  top: 24px;
  right: 32px;
  font-size: 13px;
  font-weight: 300;
  color: ${({ theme }) => theme.text2};
  text-align: right;
  max-width: 40%;

  a {
    color: ${({ theme }) => theme.text1} !important;
    text-decoration: none !important;
    font-weight: 600 !important;
  }

  @media screen and (max-width: 900px) {
    font-size: 12px;
  }

  @media screen and (max-width: 600px) {
    font-size: 12px;
  }

  @media screen and (max-width: 525px) {
    font-size: 11px;
  }

  @media screen and (max-width: 465px) {
    top: 95px;
    max-width: calc(100% - 64px);
    text-align: left;

    br {
      display: none;
    }

    a {
      margin-left: 4px;
    }
  }
`

const CollectWrapper = styled.div`
  width: 100%;
  position: absolute;
  background: ${({ theme }) => theme.bg6};
  bottom: 0;
  left: 0;
  height: 85px;

  @media screen and (max-width: 900px) {
    height: 70px;
  }

  @media screen and (max-width: 465px) {
    height: 136px;
  }
`

const CollectText = styled.div`
  position: absolute;
  left: 32px;
  bottom: 33px;
  font-size: 14px;
  font-weight: 300;

  > svg {
    transform: translate(-3px, 2px);
  }

  @media screen and (max-width: 900px) {
    font-size: 13px;
    bottom: 27px;
  }

  @media screen and (max-width: 600px) {
    max-width: 37%;
    bottom: 17px;
  }

  @media screen and (max-width: 500px) {
    max-width: 34%;
    font-size: 12px;
    bottom: 19px;
  }

  @media screen and (max-width: 465px) {
    max-width: 100%;
    bottom: 100px;
    width: calc(100% - 64px);
    text-align: center;
  }
`

const CollectSection = styled.div`
  position: absolute;
  bottom: 24px;
  right: 32px;
  width: fit-content;

  @media screen and (max-width: 900px) {
    bottom: 18px;
  }

  @media screen and (max-width: 465px) {
    width: calc(100% - 64px);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    bottom: 24px;
  }
`

const CollectAmount = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
  display: inline-block;
  vertical-align: top;
  margin-right: 8px;
  margin-top: 2px;

  @media screen and (max-width: 900px) {
    font-size: 22px;
  }

  @media screen and (max-width: 500px) {
    font-size: 20px;
  }

  @media screen and (max-width: 465px) {
    font-size: 27px;
    margin-bottom: 5px;
    text-align: center;
    margin-right: 0;
  }
`

const CollectButton = styled.div<{ active?: boolean }>`
  background-color: ${({ active, theme }) => (active ? theme.green2 : theme.bg4)};
  color: ${({ theme }) => theme.text1};
  font-weight: 600;
  font-size: 16px;
  padding: 8px 24px;
  border-radius: 5px;
  cursor: ${({ active }) => (active ? 'pointer' : 'default')};
  display: inline-block;
  vertical-align: top;
  min-width: 100px;
  height: 37.5px;
  text-align: center;

  > svg,
  > div {
    color: ${({ theme }) => theme.text1} !important;
    font-size: 20px;
    height: 20px !important;
    width: 20px !important;
  }

  > div > svg {
    transform: translate(0, 0);
  }

  @media screen and (max-width: 900px) {
    font-size: 14px;
  }

  @media screen and (max-width: 500px) {
    font-size: 12px;
  }

  @media screen and (max-width: 465px) {
    width: 100%;
    text-align: center;
  }

  :hover {
    background-color: ${({ active, theme }) => (active ? theme.green1 : theme.bg4)};
  }
`

const ComingSoonInfo = styled.div`
  width: 100%;
  max-width: 900px;
  background: ${({ theme }) => theme.bg1};
  border-radius: 8px;
  padding: 20px 30px;
  margin: 0 auto 20px;
  font-size: 15px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);

  @media screen and (max-width: 919px) {
    max-width: 70vw;
  }

  @media screen and (max-width: 730px) {
    width: 80vw;
    max-width: 1400px;
  }

  @media screen and (max-width: 580px) {
    width: calc(100% - 40px);
  }
`

const SEASON_NUMBER = 0

type AccountToEpochToClaimMap = Record<string, Record<number, boolean> | undefined>

export default function Minerals() {
  const { account, chainId } = useActiveWeb3React()
  const networkLabel = NETWORK_LABELS[chainId]
  const { t } = useTranslation()
  const mineralToken = useMineralToken()
  const [isCollecting, setIsCollecting] = useState(false)
  const [awaitingSignature, setAwaitingSignature] = useState(false)
  const [pendingHash, setPendingHash] = useState<string | undefined>(undefined)
  const [accountToEpochToClaimedMap, setAccountToEpochToClaimedMap] = useState<AccountToEpochToClaimMap>({})
  const { data: claimData, loading: loadingUncollectedMinerals } = useAllLiquidityMiningClaims(
    account,
    SEASON_NUMBER,
    mineralToken,
  )
  const externalMinerals = useExternalMinerals(claimData.allClaims, claimData.unfinalizedClaims)
  const totalMineralsCollected = useMineralTokenBalance(account)
  const eligibleTokens = useTokensWithMineralRewards()
  const eligibleTokenToRewardsMap = useTokenToMineralsPerWeekMap()
  const { data: tokenBalancesData } = useAggregateBalanceDataForMinerals(account)
  const projectedMineralsData = useProjectedMineralsThisWeek(tokenBalancesData, claimData.allClaims)
  const currentMineralsThisWeek = projectedMineralsData?.currentAmount
  const projectedMinerals = projectedMineralsData?.projectedAmount
  const { currentMultiplier, highestMultiplier, previousWeekMineralAmount, boost } = useMineralMultiplierInfo(
    projectedMineralsData,
    claimData.allClaims,
    claimData.maxEpoch,
  )
  const isBoostedMultiplier = !!boost
  const { data: rank } = useLiquidityMiningLeaderboardRankByAccount(mineralToken, account)
  const { callback: collectCallback } = useClaimLiquidityMiningRewards(claimData.unfinalizedClaims, mineralToken)

  const [hasVisitedMinerals, setHasVisitedMinerals] = useState(true)
  const [hasApprovedToS, setHasApprovedToS] = useApprovedToS()

  useEffect(() => {
    const localStorageContents = window.localStorage.getItem('dolomite_has_visited_minerals')
    if (!localStorageContents || localStorageContents !== 'true') {
      setHasVisitedMinerals(false)
    }
  }, [])

  const setHasVisited = useCallback(() => {
    window.localStorage.setItem('dolomite_has_visited_minerals', 'true')
    setHasVisitedMinerals(true)
  }, [])

  const setHasCompletedApprovals = useCallback(() => {
    setHasApprovedToS()
    setHasVisited()
  }, [setHasApprovedToS, setHasVisited])

  const totalUncollectedMinerals = useMemo(() => {
    if (!mineralToken) {
      return undefined
    }
    return claimData.unfinalizedClaims?.reduce(
      (total: CurrencyAmount<Token>, uncollectedClaim: LiquidityMiningClaim) => {
        return uncollectedClaim.isEpochFinalized && !accountToEpochToClaimedMap[account ?? '']?.[uncollectedClaim.epoch]
          ? total.add(uncollectedClaim.amount)
          : total
      },
      CurrencyAmount.fromRawAmount(mineralToken, BIG_INT_ZERO),
    )
  }, [mineralToken, claimData.unfinalizedClaims, accountToEpochToClaimedMap, account])

  const isHashPending = useIsTransactionPending(pendingHash)
  useEffect(() => {
    if (!isHashPending && pendingHash) {
      setPendingHash(undefined)
      setAccountToEpochToClaimedMap(prevState => {
        const unclaimedEpochMap = claimData.unfinalizedClaims?.reduce<Record<number, boolean>>(
          (map, unclaimedEpoch) => {
            return {
              ...map,
              [unclaimedEpoch.epoch]: true,
            }
          },
          {},
        )
        return {
          ...prevState,
          [account ?? '']: {
            ...prevState[account ?? ''],
            ...unclaimedEpochMap,
          },
        }
      })
      setIsCollecting(false)
    }
  }, [account, isHashPending, pendingHash, claimData.unfinalizedClaims])

  const onCollect = useCallback(() => {
    if (!collectCallback || awaitingSignature) {
      return
    }

    setIsCollecting(true)
    setAwaitingSignature(true)
    collectCallback()
      .then(hash => {
        setPendingHash(hash)
        setAwaitingSignature(false)
      })
      .catch(() => {
        setAwaitingSignature(false)
        setIsCollecting(false)
      })
  }, [awaitingSignature, collectCallback])

  return (
    <MineralsWrapper>
      <PageTitle title={chainId === ChainId.BERACHAIN ? 'Bolomite | Minerals' : 'Dolomite | Minerals'} />
      {(!hasVisitedMinerals || !hasApprovedToS) && (
        <FirstVisitPopoverMinerals approvedToS={hasApprovedToS} setHasCompletedApprovals={setHasCompletedApprovals} />
      )}
      {chainId === ChainId.BERACHAIN && (
        <ComingSoonInfo>
          {`Minerals are a program to recognize those in the community that supply the assets that are commonly borrowed for strategies performed on Dolomite. With Berachain still only in testnet, it's not quite time for Minerals to make their debut here, but that day will come soon enough!`}
        </ComingSoonInfo>
      )}
      <TopSection disabled={chainId === ChainId.BERACHAIN}>
        <Title>{t('dolomiteMinerals')}</Title>
        <Descriptor>{t('mineralsDescription')}</Descriptor>
        <TopRow>
          <MineralsTotal>
            <PanelTitle>
              {t('totalMineralsCollectedOnNetwork', { network: networkLabel })}{' '}
              <StyledTooltipWithIcon tooltipText={t('totalMineralsCollectedTooltip')} />
            </PanelTitle>
            <PanelValue>{formatAmount(totalMineralsCollected, 2, true, '-')}</PanelValue>
          </MineralsTotal>
          <Panel>
            <PanelTitle>
              {t('rank')} <StyledTooltipWithIcon tooltipText={t('rankTooltip')} />
            </PanelTitle>
            <PanelValue>
              {rank?.equalTo(69) ? (
                <Rank69>{formatAmount(rank, 0, true, '-')}</Rank69>
              ) : (
                formatAmount(rank, 0, true, '-')
              )}
              <RankSuperscript>
                {rank ? (
                  rank.equalTo(69) ? (
                    <Rank69>th</Rank69>
                  ) : (rank || 0).toFixed(0).endsWith('1') && !(rank || 0).toFixed(0).endsWith('11') ? (
                    'st'
                  ) : (rank || 0).toFixed(0).endsWith('2') && !(rank || 0).toFixed(0).endsWith('12') ? (
                    'nd'
                  ) : (rank || 0).toFixed(0).endsWith('3') && !(rank || 0).toFixed(0).endsWith('13') ? (
                    'rd'
                  ) : (
                    'th'
                  )
                ) : (
                  ''
                )}
              </RankSuperscript>
            </PanelValue>
          </Panel>
          <Panel>
            {isBoostedMultiplier && <Sparkle color={'#3a86f3'} />}
            <PanelTitle>
              {t('multiplier')}{' '}
              <StyledTooltipWithIcon tooltipText={t('multiplierTooltip', { network: networkLabel })} />
            </PanelTitle>
            {boost && <MultiplierBoostWrapper>Boosted {boost.toFixed(0)}x</MultiplierBoostWrapper>}
            {!currentMultiplier.equalTo(highestMultiplier) && !highestMultiplier.equalTo(ONE_FRACTION) ? (
              <StyledTooltip title={t('losingMultiplierTooltip')} placement={'top'}>
                <PanelValue isAtRisk={true} adjustForLength={highestMultiplier.greaterThanOrEqual(10)}>
                  {highestMultiplier.toFixed(1)}
                  <Thin>x</Thin>
                  <MultiplierRiskDescription>At risk of losing multiplier</MultiplierRiskDescription>
                </PanelValue>
              </StyledTooltip>
            ) : (
              <PanelValue
                isAtRisk={!currentMultiplier.equalTo(highestMultiplier) && !highestMultiplier.equalTo(ONE_FRACTION)}
                isBoosted={isBoostedMultiplier}
                adjustForLength={highestMultiplier.greaterThanOrEqual(10)}
              >
                {highestMultiplier.toFixed(1)}
                <Thin>x</Thin>
              </PanelValue>
            )}
          </Panel>
        </TopRow>
        <SecondRow>
          <PanelColumn numPanels={eligibleTokens.length}>
            {eligibleTokens.map(token => {
              const symbol = cleanCurrencySymbol(token) ?? ''
              return (
                <Panel key={`panel-${token.chainId}-${token.address}`}>
                  <PanelTitle>
                    {t('assetBalance', { asset: symbol })}{' '}
                    <StyledTooltipWithIcon tooltipText={t('assetBalanceTooltip', { asset: symbol })} />
                  </PanelTitle>
                  <PanelValue>{formatAmount(tokenBalancesData?.[token.address])}</PanelValue>
                  <PanelBonusRow>
                    <div>
                      <b>
                        +
                        {formatAmount(
                          eligibleTokenToRewardsMap[token.address]?.amount,
                          eligibleTokenToRewardsMap[token.address]?.displayDecimals,
                        )}
                      </b>{' '}
                      {t('tokenPerMineralPerWeek', { asset: symbol })}
                    </div>
                  </PanelBonusRow>
                </Panel>
              )
            })}
          </PanelColumn>
          {externalMinerals && externalMinerals.externalMineralSources.length > 0 && (
            <ExternalMineralsPanel>
              <PanelTitle>External Minerals</PanelTitle>
              <PanelSubtitle>
                {`Some deposits outside of Dolomite earn Minerals, and you can track Minerals earned from those sources
              here. Because these external sources can have built-in multipliers, Minerals earned from these sources 
              don't qualify for the multiplier shown on this page. This data refreshes approximately once an hour.`}
              </PanelSubtitle>
              {externalMinerals?.externalMineralSources.map(source => (
                <ExternalMinealsRow key={source.title}>
                  <ExternalMineralsRowTop>
                    <ExternalMineralsTitle>
                      <ExternalLink href={source.link}>
                        {source.title} (<ExternalMultiplier>{source.multiplier.toFixed(0)}x</ExternalMultiplier>)
                      </ExternalLink>{' '}
                      <StyledTooltipWithIcon
                        tooltipText={
                          'The amount of Minerals earned and available to claim. The amount is added to your claimable amount shown in the panel below.'
                        }
                      />
                    </ExternalMineralsTitle>
                    <ExternalMineralsAmount>{formatAmount(source.claimableAmount, 2, true)}</ExternalMineralsAmount>
                  </ExternalMineralsRowTop>
                  <ExternalMineralsRowBottom>
                    <ExternalMineralsTotalTitle>
                      Total collected from {source.titleShort}{' '}
                      <StyledTooltipWithIcon
                        tooltipText={'Total earned across all weeks, not just the current week.'}
                      />
                    </ExternalMineralsTotalTitle>
                    <ExternalMineralsTotalAmount>
                      {formatAmount(source.totalAmountEarned, 2, true)}
                    </ExternalMineralsTotalAmount>
                  </ExternalMineralsRowBottom>
                  <ExternalMineralsRowBottom>
                    <ExternalMineralsTotalTitle>
                      Amount earned this week from {source.titleShort}{' '}
                      <StyledTooltipWithIcon
                        tooltipText={
                          'Total un-claimable amount from the current week. When the week rolls over on Thursday at 00:15:00 UTC, this amount will become claimable.'
                        }
                      />
                    </ExternalMineralsTotalTitle>
                    <ExternalMineralsTotalAmount>
                      {formatAmount(source.pendingAmount, 2, true)}
                    </ExternalMineralsTotalAmount>
                  </ExternalMineralsRowBottom>
                </ExternalMinealsRow>
              ))}
            </ExternalMineralsPanel>
          )}
          <EarnPanel showExternal={externalMinerals?.totalExternalMinerals.greaterThan(ZERO_FRACTION) ?? false}>
            <EarnedThisWeekWrapper>
              <PanelTitle>
                {t('mineralsEarnedThisWeek', { network: networkLabel })}{' '}
                <StyledTooltipWithIcon tooltipText={t('mineralsEarnedThisWeekTooltip', { network: networkLabel })} />
              </PanelTitle>
              <PanelValue>{formatAmount(currentMineralsThisWeek, 2, true, '-')}</PanelValue>
            </EarnedThisWeekWrapper>
            <DataRowsWrapper>
              <EarnDataRow>
                <EarnLabel>
                  {t('onTrackToEarnThisWeek')} <StyledTooltipWithIcon tooltipText={t('onTrackToEarnThisWeekTooltip')} />
                </EarnLabel>
                <EarnValue>{formatAmount(projectedMinerals, 2, true, '-')}</EarnValue>
              </EarnDataRow>
              <EarnDataRow>
                <EarnLabel
                  warn={!currentMultiplier.equalTo(highestMultiplier) && !highestMultiplier.equalTo(ONE_FRACTION)}
                >
                  {t('amountToRetainMult')} <StyledTooltipWithIcon tooltipText={t('amountToRetainMultTooltip')} />
                </EarnLabel>
                <EarnValue>{formatAmount(previousWeekMineralAmount, 2, true, '-')}</EarnValue>
              </EarnDataRow>
              <EarnDataRow>
                <EarnLabel>
                  {t('onTrackToEarn')} (
                  <MultiplierColor
                    isAtRisk={!currentMultiplier.equalTo(highestMultiplier) && !highestMultiplier.equalTo(ONE_FRACTION)}
                  >
                    {highestMultiplier.toFixed(2)}x
                  </MultiplierColor>
                  ) <StyledTooltipWithIcon tooltipText={t('onTrackToEarnTooltip')} />
                </EarnLabel>
                <EarnValue
                /*isAtRisk={!currentMultiplier.equalTo(highestMultiplier) && !highestMultiplier.equalTo(ONE_FRACTION)}*/
                >
                  {formatAmount(projectedMinerals?.multiply(highestMultiplier), 2, true, '-')}
                </EarnValue>
              </EarnDataRow>
              {externalMinerals?.totalExternalMinerals.greaterThan(ZERO_FRACTION) && (
                <EarnDataRow>
                  <EarnLabel>
                    Earned from external sources{' '}
                    <StyledTooltipWithIcon
                      tooltipText={
                        'Total minerals earned from external sources that can be claimed. Track the amounts and sources in the above panel.'
                      }
                    />
                  </EarnLabel>
                  <EarnValue>{formatAmount(externalMinerals?.totalExternalMinerals, 2, true, '-')}</EarnValue>
                </EarnDataRow>
              )}
            </DataRowsWrapper>
            <ExplainerWrapper>
              {t('earnExplainer')}
              <br />
              <a href={'https://docs.dolomite.io/minerals'} target={'_blank'} rel={'noreferrer'}>
                {t('learnMore')}
              </a>
            </ExplainerWrapper>
            <CollectWrapper>
              <CollectText>
                {t('earnedMineralsAvailable')}{' '}
                <StyledTooltipWithIcon tooltipText={t('earnedMineralsAvailableTooltip')} />
              </CollectText>
              <CollectSection>
                <CollectAmount>
                  {loadingUncollectedMinerals && !totalUncollectedMinerals
                    ? '-'
                    : formatAmount(totalUncollectedMinerals, 2, true, '-')}
                </CollectAmount>
                <CollectButton
                  active={!isCollecting && totalUncollectedMinerals?.greaterThan(ZERO_FRACTION)}
                  onClick={totalUncollectedMinerals?.greaterThan(ZERO_FRACTION) ? onCollect : undefined}
                >
                  {isCollecting ? <CircularProgress /> : t('collect')}
                </CollectButton>
              </CollectSection>
            </CollectWrapper>
          </EarnPanel>
        </SecondRow>
      </TopSection>
      <Leaderboard />
    </MineralsWrapper>
  )
}
