import { Fraction } from '@dolomite-exchange/sdk-core'
import { MarketRiskInfo } from '../types/marketRiskInfoData'
import calculateScaledValueForRiskParams from './calculateScaledValueForRiskParams'
import { ZERO_FRACTION } from '../constants'
import { BorrowPositionAmount } from '../types/borrowPositionData'
import { CurrencyAmount } from '@dolomite-exchange/v2-sdk'
import { Token } from '@dolomite-exchange/sdk-core'

export function calculateStrategyHealth(
  supplyAmounts: CurrencyAmount<Token>[],
  borrowAmounts: CurrencyAmount<Token>[],
  marketRiskInfoMap: Record<string, MarketRiskInfo | undefined>,
  minCollateralization: Fraction,
  fiatValueMap: Record<string, Fraction | undefined>,
): Fraction | undefined {
  if (Object.keys(marketRiskInfoMap).length === 0) {
    return undefined
  }

  try {
    const scaledTotalSupplyAmount = supplyAmounts.reduce((acc, supplyAmount) => {
      const address = supplyAmount.currency.address
      const fiatValue = fiatValueMap[address] ?? ZERO_FRACTION
      const riskInfo = marketRiskInfoMap[address]
      if (!riskInfo) {
        console.error('No risk info for supply token', address)
        throw new Error('No risk info for supply token')
      }
      return acc.add(calculateScaledValueForRiskParams(true, supplyAmount.asFraction.multiply(fiatValue), riskInfo))
    }, ZERO_FRACTION)

    const scaledTotalBorrowAmount = borrowAmounts.reduce((acc, borrowAmount) => {
      const address = borrowAmount.currency.address
      const fiatValue = fiatValueMap[address] ?? ZERO_FRACTION
      const riskInfo = marketRiskInfoMap[address]
      if (!riskInfo) {
        console.error('No risk info for borrow token', address)
        throw new Error('No risk info for borrow token')
      }
      return acc.add(calculateScaledValueForRiskParams(false, borrowAmount.asFraction.multiply(fiatValue), riskInfo))
    }, ZERO_FRACTION)

    if (scaledTotalBorrowAmount.equalTo(ZERO_FRACTION)) {
      return undefined
    } else {
      return scaledTotalSupplyAmount.divide(scaledTotalBorrowAmount.multiply(minCollateralization))
    }
  } catch (e) {
    return undefined
  }
}

export default function calculateBorrowPositionHealth(
  supplyAmounts: BorrowPositionAmount[],
  borrowAmounts: BorrowPositionAmount[],
  marketRiskInfoMap: Record<string, MarketRiskInfo | undefined>,
  minCollateralization: Fraction,
): Fraction | undefined {
  if (Object.keys(marketRiskInfoMap).length === 0) {
    return undefined
  }

  try {
    const scaledTotalSupplyAmount = supplyAmounts.reduce((acc, supplyAmount) => {
      const riskInfo = marketRiskInfoMap[supplyAmount.token.address]
      if (!riskInfo) {
        console.error('No risk info for supply token', supplyAmount.token.symbol, supplyAmount.token.address)
        throw new Error('No risk info for supply token')
      }
      return acc.add(calculateScaledValueForRiskParams(true, supplyAmount.amountUSD, riskInfo))
    }, ZERO_FRACTION)

    const scaledTotalBorrowAmount = borrowAmounts.reduce((acc, borrowAmount) => {
      const riskInfo = marketRiskInfoMap[borrowAmount.token.address]
      if (!riskInfo) {
        console.error('No risk info for borrow token', borrowAmount.token.symbol, borrowAmount.token.address)
        throw new Error('No risk info for borrow token')
      }
      return acc.add(calculateScaledValueForRiskParams(false, borrowAmount.amountUSD, riskInfo))
    }, ZERO_FRACTION)

    if (scaledTotalBorrowAmount.equalTo(ZERO_FRACTION)) {
      return undefined
    } else {
      return scaledTotalSupplyAmount.divide(scaledTotalBorrowAmount.multiply(minCollateralization))
    }
  } catch (e) {
    return undefined
  }
}
