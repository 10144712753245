import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components/macro'
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import Header from '../components/Header'
import Polling from '../components/Header/Polling'
import Popups from '../components/Popups'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import Trade from './Trade'
import Balances from './Balances'
import Pool from './Pool'
import HistoryPage from './History'
import Stats from './Stats'
import PoolStats from './Stats/PoolStats'
import TokenStats from './Stats/TokenStats'
import Achievements from './Achievements'
import Rewards from './Rewards'
import Leaderboard from './Leaderboard'
import Account from './Leaderboard/Account'
import MarginPosition from './Leaderboard/Position'
import { RedirectPathToBalancesOnly } from './Trade/redirects'
import * as Sentry from '@sentry/react'
import Borrow from './Borrow'
import Chainalysis from '../components/Header/Chainalysis'
import Minerals from './Minerals'
import Strategies from './Strategies'
import { useGalxeAddressData } from '../types/galxeAddressData'
import { useActiveWeb3React } from '../hooks'

const AppWrapper = styled.div`
  overflow-y: auto;
  max-height: 100vh;
  width: 100vw;
  display: contents;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  position: relative;
  z-index: 10;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 60px;
  `};
`

const SuperUltraWideWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;

  @media screen and (min-width: 5000px) {
    > div {
      margin: 40px 0 0 0 !important;
      width: 1250px !important;
      max-width: 1250px !important;
      display: inline-block;
      vertical-align: top;
      height: calc(100vh - 112px);
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
`

const BodyWrapper = styled.div``

const Marginer = styled.div`
  /*margin-top: 5rem;*/
`

function TopLevelModals() {
  return <></>
}

export const MIN_LEVEL_FOR_STRATEGIES = 5

const SentryRoute = Sentry.withSentryRouting(Route)

export const PageSizeContext = React.createContext({
  x: window.innerWidth,
  y: window.innerHeight,
})

export const WHITELIST = [
  '0xD360EcB91406717Ad13C4fae757b69B417E2Af6b'.toUpperCase(),
  '0x532f77ACf02E9B45B7dF27C93dBF8795ff01830c'.toUpperCase(),
  '0x186B4E576E34974c776aB8048970AefD9C71468e'.toUpperCase(),
  '0xD7040140B51125fBff24f6b6d3Df096c9757fb85'.toUpperCase(),
]

export default function App() {
  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight,
  })
  const updateSize = useCallback(
    () =>
      setSize({
        x: window.innerWidth,
        y: window.innerHeight,
      }),
    [],
  )
  useEffect(() => {
    window.onresize = updateSize
    return () => {
      window.onresize = null
    }
  }, [updateSize])

  //remove soon
  const { account, chainId } = useActiveWeb3React()
  const { levelData, loading } = useGalxeAddressData(account)
  //const level = 5
  const { level } = levelData
  const whitelisted = account ? WHITELIST.includes(account.toUpperCase()) : undefined
  //const level = 10
  return (
    <Sentry.ErrorBoundary>
      <PageSizeContext.Provider value={size}>
        <Suspense fallback={null}>
          <Route component={GoogleAnalyticsReporter} />
          <Route component={DarkModeQueryParamReader} />
          <AppWrapper>
            <HeaderWrapper>
              <Header />
            </HeaderWrapper>
            <BodyWrapper>
              <Popups />
              <Chainalysis />
              <Polling />
              <TopLevelModals />
              {size.x > 5000 ? (
                <SuperUltraWideWrapper>
                  <Balances />
                  <Borrow />
                  <SentryRoute exact strict path='/' component={Stats} />
                  <SentryRoute exact strict path='/balances' component={Stats} />
                  <SentryRoute exact strict path='/stats/pool/:poolId' component={PoolStats} />
                  <SentryRoute exact strict path='/stats/token/:tokenId' component={TokenStats} />
                  <Achievements />
                </SuperUltraWideWrapper>
              ) : (
                <Switch>
                  <SentryRoute exact strict path='/trade/:inputCurrency/:outputCurrency' component={Trade} />
                  <SentryRoute exact strict path='/balances' component={Balances} />
                  <SentryRoute exact strict path='/pool' component={Pool} />
                  <SentryRoute exact strict path='/pool/:poolInputCurrency' component={Pool} />
                  <SentryRoute exact strict path='/pool/:poolInputCurrency/:poolOutputCurrency' component={Pool} />
                  <SentryRoute exact strict path='/borrow' component={Borrow} />
                  <SentryRoute exact strict path='/history' component={HistoryPage} />
                  <SentryRoute exact strict path='/history/:page' component={HistoryPage} />
                  <SentryRoute exact strict path='/stats' component={Stats} />
                  <SentryRoute exact strict path='/stats/pool/:poolId' component={PoolStats} />
                  <SentryRoute exact strict path='/stats/token/:tokenId' component={TokenStats} />
                  <SentryRoute exact strict path='/achievements' component={Achievements} />
                  <SentryRoute exact strict path='/achievements/:page' component={Achievements} />
                  <SentryRoute exact strict path='/rewards' component={Rewards} />
                  <SentryRoute exact strict path='/leaderboard' component={Leaderboard} />
                  <SentryRoute exact strict path='/account/:address' component={Account} />
                  <SentryRoute exact strict path='/position/:id' component={MarginPosition} />
                  <SentryRoute exact strict path='/minerals' component={Minerals} />
                  <SentryRoute exact strict path='/strategies' component={Strategies} />
                  <SentryRoute exact strict path='/strategies/:id' component={Strategies} />
                  <SentryRoute exact strict path='/strategies/:id/:positionId' component={Strategies} />
                  <SentryRoute component={RedirectPathToBalancesOnly} />
                </Switch>
              )}
              <Marginer />
            </BodyWrapper>
          </AppWrapper>
        </Suspense>
      </PageSizeContext.Provider>
    </Sentry.ErrorBoundary>
  )
}
