import { Fraction, Percent, Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ONE_FRACTION, UNI, USDC, USDT, W_USDM, WE_ETH, WETH, WMNT, WST_ETH } from '../../constants'
import { METH } from '../../constants/tokens/METH'
import { GRAI } from '../../constants/tokens/GRAI'
import { RS_ETH } from '../../constants/tokens/RS_ETH'
import { PT_METH_DEC_2024_SPECIAL_ASSET } from '../../constants/isolation/assets/PT_METH_DEC_2024_SPECIAL_ASSET'
import { BorrowPosition } from '../../types/borrowPositionData'
import { useChainIdToTokenToFiatPriceMap, useFiatPricesWithLoadingIndicator } from '../../hooks/useFiatValue'
import { tryParseAmount } from '../../state/trade/hooks'
import { CurrencyAmount } from '@dolomite-exchange/v2-sdk'
import { getStrategyInterestRate } from '../../hooks/useNetInterestRate'
import { useMemo } from 'react'
import { formatAmount } from '../../utils/formatAmount'
import { RefreshFrequency } from '../../state/chain/hooks'
import { InterestRate, useChainIdToInterestRateMap } from '../../types/interestRateData'
import { USDE } from '../../constants/tokens/USDE'
import { PT_USDE_DEC_2024_SPECIAL_ASSET } from '../../constants/isolation/assets/PT_USDE_DEC_2024_SPECIAL_ASSET'
import { EZ_ETH } from '../../constants/tokens/EZ_ETH'
import { GM_ETH_SPECIAL_ASSET } from '../../constants/isolation/assets/GM_ETH_SPECIAL_ASSET'
import { SBF_GMX } from '../../constants/tokens/SBF_GMX'
import { GMX } from '../../constants/tokens/GMX'
import { GMX_SPECIAL_ASSET } from '../../constants/isolation/assets/GMX_SPECIAL_ASSET'
import { RDNT } from '../../constants/tokens/RDNT'
import { BRIDGED_USDC } from '../../constants/tokens/USDC'

const PT_METH = PT_METH_DEC_2024_SPECIAL_ASSET().isolationModeInfo?.getWrappedToken(ChainId.MANTLE)
const PT_USDE = PT_USDE_DEC_2024_SPECIAL_ASSET().isolationModeInfo?.getWrappedToken(ChainId.MANTLE)
const GM_ETH = GM_ETH_SPECIAL_ASSET().isolationModeInfo?.getWrappedToken(ChainId.ARBITRUM_ONE)
const S_GMX = GMX_SPECIAL_ASSET().isolationModeInfo?.getWrappedToken(ChainId.ARBITRUM_ONE)

export const STRATEGY_INDEX_LOWER = 100_000
export const STRATEGY_INDEX_UPPER = 1_000_000

export enum Risk {
  low = 'Low',
  medium = 'Med',
  high = 'High',
}

export enum StrategyTagType {
  filter,
  reward,
}

export interface StrategyTag {
  name: string
  color: string
  tooltip: string
  type: StrategyTagType
}

export const Tags: Record<string, StrategyTag> = {
  yieldMax: {
    name: 'Yield Maximizing',
    color: '#7fa225',
    tooltip:
      ' strategies are strategies designed to produce the highest yield, taking advantage of the difference in borrow and supply rates, as well as additional rewards.',
    type: StrategyTagType.filter,
  },
  ethStaking: {
    name: 'ETH Staking',
    color: '#7fa225',
    tooltip: ' strategies utilize the yield earned from staked ETH.',
    type: StrategyTagType.filter,
  },
  deltaNeutral: {
    name: 'Δ Neutral',
    color: '#5d869e',
    tooltip:
      ' or "delta neutral" strategies are ones designed to be resistant to changes in the price of assets. This is done having debt and collateral that are correlated in price.',
    type: StrategyTagType.filter,
  },
  rewardsMax: {
    name: 'Rewards Maximizing',
    color: '#7fa225',
    tooltip:
      ' strategies are strategies designed to maximize the rewards you earn. These rewards can take the form of tokens, points, or other rewards.',
    type: StrategyTagType.filter,
  },
  lrt: {
    name: 'LRT',
    color: '#05a47b',
    tooltip:
      ' strategies utilize "liquid restaking tokens", which are tokens received when users deposit ETH in any number of liquid restaking protocols. They earn ETH staking yield.',
    type: StrategyTagType.filter,
  },
  lst: {
    name: 'LST',
    color: '#05a47b',
    tooltip:
      ' strategies utilize "liquid staking tokens", which are tokens received when users deposit ETH in any number of liquid staking protocols. They earn ETH staking yield, as well as Actively Validated Services or AVS yield.',
    type: StrategyTagType.filter,
  },
  ethExposure1x: {
    name: '1x Eth Exposure',
    color: '#e59006',
    tooltip:
      ' strategies have 1x exposure to the price of ETH, meaning that the value of your equity in the strategy will increase and decrease at approximately the same rate that the price of ETH increases or decreases.',
    type: StrategyTagType.filter,
  },
  ethExposure2x: {
    name: '2x Eth Exposure',
    color: '#e59006',
    tooltip:
      ' strategies have 2x exposure to the price of ETH, meaning that the value of your equity in the strategy will increase and decrease at approximately twice the rate that the price of ETH increases or decreases.',
    type: StrategyTagType.filter,
  },
  ethExposure3x: {
    name: '3x Eth Exposure',
    color: '#e59006',
    tooltip:
      ' strategies have 3x exposure to the price of ETH, meaning that the value of your equity in the strategy will increase and decrease at approximately three times the rate that the price of ETH increases or decreases.',
    type: StrategyTagType.filter,
  },
  ethExposure4x: {
    name: '4x Eth Exposure',
    color: '#e59006',
    tooltip:
      ' strategies have 4x exposure to the price of ETH, meaning that the value of your equity in the strategy will increase and decrease at approximately four times the rate that the price of ETH increases or decreases.',
    type: StrategyTagType.filter,
  },
  ethBull: {
    name: 'ETH Bull',
    color: '#e59006',
    tooltip:
      ' strategies are for those who believe the value of ETH will rise. These strategies generally have leveraged exposure to the price of ETH.',
    type: StrategyTagType.filter,
  },
  leveragedExposure: {
    name: 'Leveraged Exposure',
    color: '#e59006',
    tooltip:
      ' strategies have leveraged exposure to the price of an asset, meaning that the value of your equity in the strategy will increase and decrease at some multiple of the rate that the underlying asset increases or decreases in price.',
    type: StrategyTagType.filter,
  },
  expirable: {
    name: 'Expirable',
    color: '#D33E43',
    tooltip:
      ' strategies are ones that use assets that mature or expire at a certain date, and as such are only viable until that time.',
    type: StrategyTagType.filter,
  },
  leverage5x: {
    name: '5x',
    color: '#8fc942',
    tooltip:
      ' strategies are looped so that when active, their collateral is 5x your deposit amount. This leverage does not necessarily mean any additional price exposure, there are looped strategies that are delta neutral or with 1x price exposure while still increasing yield. However, strategies with higher leverage have a greater amount borrowed relative to collateral, and as a result have a lower health factor.',
    type: StrategyTagType.filter,
  },
  leverage4x: {
    name: '4x',
    color: '#8fc942',
    tooltip:
      ' strategies are looped so that when active, their collateral is 4x your deposit amount. This leverage does not necessarily mean any additional price exposure, there are looped strategies that are delta neutral or with 1x price exposure while still increasing yield. However, strategies with higher leverage have a greater amount borrowed relative to collateral, and as a result have a lower health factor.',
    type: StrategyTagType.filter,
  },
  leverage3x: {
    name: '3x',
    color: '#8fc942',
    tooltip:
      ' strategies are looped so that when active, their collateral is 3x your deposit amount. This leverage does not necessarily mean any additional price exposure, there are looped strategies that are delta neutral or with 1x price exposure while still increasing yield. However, strategies with higher leverage have a greater amount borrowed relative to collateral, and as a result have a lower health factor.',
    type: StrategyTagType.filter,
  },
  leverage2x: {
    name: '2x',
    color: '#8fc942',
    tooltip:
      ' strategies are looped so that when active, their collateral is 2x your deposit amount. This leverage does not necessarily mean any additional price exposure, there are looped strategies that are delta neutral or with 1x price exposure while still increasing yield. However, strategies with higher leverage have a greater amount borrowed relative to collateral, and as a result have a lower health factor.',
    type: StrategyTagType.filter,
  },
  minerals: {
    name: 'Minerals',
    color: '#2b6dc2',
    tooltip: ' strategies earn Dolomite Minerals, a reward token that can be tracked on the Minerals page.',
    type: StrategyTagType.reward,
  },
  powder: {
    name: 'Powder',
    color: '#2b6dc2',
    tooltip: ' strategies earn Mantle Powder points, which can be utilized in the Mantle ecosystem.',
    type: StrategyTagType.reward,
  },
  kelp: {
    name: 'Kelp Miles',
    color: '#2b6dc2',
    tooltip: ' strategies earn Kelp Miles, which can be utilized in the KelpDAO ecosystem.',
    type: StrategyTagType.reward,
  },
  eigenLayer: {
    name: 'EigenLayer Points',
    color: '#2b6dc2',
    tooltip: ' strategies earn EigenLayer points, which can be utilized in the EigenLayer ecosystem.',
    type: StrategyTagType.reward,
  },
  ezPoints: {
    name: 'ezPoints',
    color: '#2b6dc2',
    tooltip: ' strategies earn ezPoints points, which can be utilized in the Renzo ecosystem.',
    type: StrategyTagType.reward,
  },
  arb: {
    name: 'ARB Rewards',
    color: '#2b6dc2',
    tooltip: ' strategies earn ARB rewards.',
    type: StrategyTagType.reward,
  },
  usdm: {
    name: 'USDM APR',
    color: '#2b6dc2',
    tooltip:
      ' strategies earn yield from USDM, a stablecoin that earns interest from US Treasuries held in the USDM Reserves.',
    type: StrategyTagType.reward,
  },
  oArb: {
    name: 'oARB Rewards',
    color: '#2b6dc2',
    tooltip:
      ' strategies earn oARB rewards, which can be claimed and vested into ARB on the Rewards page here on Dolomite.',
    type: StrategyTagType.reward,
  },
  goArb: {
    name: 'goARB Rewards',
    color: '#2b6dc2',
    tooltip: ' strategies earn goARB rewards, which can be claimed and vested into ARB on the Gravita web app.',
    type: StrategyTagType.reward,
  },
  pendle: {
    name: 'Pendle Fixed APR',
    color: '#2b6dc2',
    tooltip:
      ' strategies earn a stable yield from Pendle PT assets. This stable yield is tracked from the point when you begin using the strategy, and locking in a fixed yield rate can be a good way to secure a higher yield and make a strategy more profitable.',
    type: StrategyTagType.reward,
  },
  ascend: {
    name: 'Ascend Points',
    color: '#2b6dc2',
    tooltip: ' strategies earn Gravita Ascend points, which can be utilized in the Gravita ecosystem.',
    type: StrategyTagType.reward,
  },
}

export enum OracleType {
  chainlink,
  chronicle,
  other,
}

export interface StrategyInfo {
  id: string
  title: string
  tags: StrategyTag[]
  collateralAssets: Token[]
  debtAssets: Token[]
  risk: Risk
  riskAssessment: string
  leverage: number
  chain: ChainId
  includeRates?: string[]
  specialTag?: string
  supplyOraclePath: string[] // TODO - update to support multiple supply and borrow assets
  supplyOracleType: OracleType
  borrowOraclePath: string[] // TODO - update to support multiple supply and borrow assets
  borrowOracleType: OracleType
  description: string
  whyItWorks: string
  risks: string
  disabled?: boolean
}

export interface StrategyWithAmounts {
  id: string
  title: string
  tags: StrategyTag[]
  collateralAssets: Token[]
  debtAssets: Token[]
  risk: Risk
  leverage: number
  chain: ChainId
  supplyAmounts: CurrencyAmount<Token>[]
  borrowAmounts: CurrencyAmount<Token>[]
  includeRates?: string[]
  specialTag?: string
}

const STRATEGIES_DATA: StrategyInfo[] = [
  {
    id: '1',
    title: 'Looped PT-mETH',
    tags: [Tags.leverage3x, Tags.yieldMax, Tags.ethExposure1x, Tags.ethStaking, Tags.pendle, Tags.expirable],
    collateralAssets: [PT_METH ?? WETH[ChainId.MANTLE]],
    debtAssets: [METH[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    risk: Risk.low,
    riskAssessment: `This is a lower risk strategy due to the correlated price of the collateral and the debt. Some risks that do exist are smart contract risk as well as the risk that borrow rates on ETH climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 3,
    chain: ChainId.MANTLE,
    includeRates: ['Pendle Fixed APR', 'ETH Staking APR'],
    supplyOraclePath: ['PT-mETH', 'ETH - Pendle TWAP', 'USD - Chronicle (Market Price)'],
    supplyOracleType: OracleType.chronicle,
    borrowOraclePath: ['Chronicle (Market Price)'],
    borrowOracleType: OracleType.chronicle,
    description: `This strategy utilizes PT-mETH as collateral in order to borrow mETH, and swaps that mETH into more PT-mETH collateral, "looping" PT-mETH. This strategy has 1x exposure to the price of mETH because the debt and collateral assets are correlated in price, so only excess collateral has exposure to price movements. This strategy earns interest in the form of PT-mETH.`,
    whyItWorks: `This strategy works because PT-mETH generally earns more interest than is paid on the mETH debt. Additionally, PT-mETH has a stable yield, so you can lock in a high yield. PT-mETH and mETH are also correlated in price, meaning that the value of the debt and collateral will move in the same direction, mitigating price exposure.`,
    risks: `If the borrow rate of mETH climbs above the yield earned on your PT-mETH, the strategy will be negative yielding. If the rate to borrow climbs far above the yield earned for an extended period, there's a chance of liquidation. Additionally, smart contract risk is always present.`,
  },
  {
    id: '2',
    title: 'Looped PT-mETH',
    tags: [Tags.leverage5x, Tags.yieldMax, Tags.ethExposure1x, Tags.ethStaking, Tags.pendle, Tags.expirable],
    collateralAssets: [PT_METH ?? WETH[ChainId.MANTLE]],
    debtAssets: [METH[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. The risk is limited due to the correlated price of the collateral and the debt, however the strategy utilizes high leverage. Some risks that do exist are smart contract risk as well as the risk that borrow rates on ETH climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 5,
    chain: ChainId.MANTLE,
    includeRates: ['Pendle Fixed APR', 'ETH Staking APR'],
    specialTag: 'Featured',
    supplyOraclePath: ['PT-mETH', 'ETH - Pendle TWAP', 'USD - Chronicle (Market Price)'],
    supplyOracleType: OracleType.chronicle,
    borrowOraclePath: ['Chronicle (Market Price)'],
    borrowOracleType: OracleType.chronicle,
    description: `This strategy utilizes PT-mETH as collateral in order to borrow mETH, and swaps that mETH into more PT-mETH collateral, "looping" PT-mETH. This strategy has 1x exposure to the price of mETH because the debt and collateral assets are correlated in price, so only excess collateral has exposure to price movements. This strategy earns interest in the form of PT-mETH.`,
    whyItWorks: `This strategy works because PT-mETH generally earns more interest than is paid on the mETH debt. Additionally, PT-mETH has a stable yield, so you can lock in a high yield. PT-mETH and mETH are also correlated in price, meaning that the value of the debt and collateral will move in the same direction, mitigating price exposure.`,
    risks: `If the borrow rate of mETH climbs above the yield earned on your PT-mETH, the strategy will be negative yielding. If the rate to borrow climbs far above the yield earned for an extended period, there's a chance of liquidation. Additionally, smart contract risk is always present.`,
  },
  {
    id: '3',
    title: 'Looped GRAI',
    tags: [Tags.leverage2x, Tags.yieldMax, Tags.rewardsMax, Tags.deltaNeutral, Tags.ascend, Tags.goArb],
    collateralAssets: [GRAI[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [USDC[ChainId.ARBITRUM_ONE]],
    risk: Risk.low,
    riskAssessment: `This is a lower risk strategy due to the correlated price of the debt and collateral. Some risks that do exist are smart contract risk as well as the risk that borrow rates on USDC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 2,
    chain: ChainId.ARBITRUM_ONE,
    includeRates: ['Pendle Fixed APR', 'ETH Staking APR'],
    supplyOraclePath: ['Ramses TWAP'],
    supplyOracleType: OracleType.other,
    borrowOraclePath: ['Chainlink (Market Price)'],
    borrowOracleType: OracleType.chainlink,
    description: `This strategy utilizes GRAI as collateral in order to borrow USDC, and swaps that USDC into more GRAI collateral, "looping" GRAI. This strategy is delta-neutral, as both the collateral and debt are stablecoins. This strategy earns interest in the form of GRAI, as well as GRAI rewards.`,
    whyItWorks: `This strategy works because the yield on GRAI, when including all rewards, is higher than the rate to borrow USDC. GRAI and USDC are both stablecoins, so the strategy doesn't have any intrinsic price exposure.`,
    risks: `While GRAI earns a higher rate of return when including rewards, those rewards are claimed off of Dolomite and don't accumulate in the collateral of this strategy. As a result, the value of the GRAI collateral is likely to increase more slowly than the value of the USDC debt, which could lead to liquidation with enough time. This risk is exacerbated with increased USDC borrow rates. Additionally, risk of depeg and smart contract risk are always present.`,
    disabled: true,
  },
  {
    id: '4',
    title: 'Looped GRAI',
    tags: [Tags.leverage4x, Tags.yieldMax, Tags.rewardsMax, Tags.deltaNeutral, Tags.ascend, Tags.goArb],
    collateralAssets: [GRAI[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [USDC[ChainId.ARBITRUM_ONE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. The correlated price of the strategy's debt and collateral helps to reduce risk, however this strategy uses higher leverage. Some risks that do exist are smart contract risk as well as the risk that borrow rates on USDC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 4,
    chain: ChainId.ARBITRUM_ONE,
    includeRates: ['Pendle Fixed APR', 'ETH Staking APR'],
    supplyOraclePath: ['Ramses TWAP'],
    supplyOracleType: OracleType.other,
    borrowOraclePath: ['Chainlink (Market Price)'],
    borrowOracleType: OracleType.chainlink,
    description: `This strategy utilizes GRAI as collateral in order to borrow USDC, and swaps that USDC into more GRAI collateral, "looping" GRAI. This strategy is delta-neutral, as both the collateral and debt are stablecoins. This strategy earns interest in the form of GRAI, as well as GRAI rewards.`,
    whyItWorks: `This strategy works because the yield on GRAI, when including all rewards, is higher than the rate to borrow USDC. GRAI and USDC are both stablecoins, so the strategy doesn't have any intrinsic price exposure.`,
    risks: `While GRAI earns a higher rate of return when including rewards, those rewards are claimed off of Dolomite and don't accumulate in the collateral of this strategy. As a result, the value of the GRAI collateral is likely to increase more slowly than the value of the USDC debt, which could lead to liquidation with enough time. This risk is exacerbated with increased USDC borrow rates. Additionally, risk of depeg and smart contract risk are always present.`,
    disabled: true,
  },
  {
    id: '5',
    title: 'Leveraged mETH',
    tags: [
      Tags.leverage2x,
      Tags.rewardsMax,
      Tags.ethExposure2x,
      Tags.ethBull,
      Tags.lst,
      Tags.minerals,
      Tags.eigenLayer,
    ],
    collateralAssets: [METH[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    debtAssets: [USDT[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. This strategy has 2x exposure to the price of ETH, so while increases in price are amplified, so are decreases in price, introducing the possibility of liquidation if the price drops too far.`,
    leverage: 2,
    includeRates: ['ETH Staking APR', 'ETH Staking APY'],
    chain: ChainId.MANTLE,
    supplyOraclePath: ['Chronicle (Market Price)'],
    supplyOracleType: OracleType.chronicle,
    borrowOraclePath: ['Chronicle (Market Price)'],
    borrowOracleType: OracleType.chronicle,
    description: `This strategy utilizes mETH as collateral in order to borrow USDT, and swaps that USDT into more mETH collateral, resulting in leveraged exposure to mETH. This strategy has 2x exposure to the price of mETH. This strategy earns interest in the form of mETH, as well as points.`,
    whyItWorks: `This strategy is designed for users who are bullish on ETH, and specifically mETH. The strategy earns leveraged interest in the form of mETH, earns leveraged points, and has 2x exposure to the price of mETH. As a result, even if the rate to borrow USDT is higher than the yield earned on mETH, the strategy can still be profitable if the price of mETH increases.`,
    risks: `This strategy has 2x exposure to the price of mETH, so your position will gain value twice as fast as the price of mETH increases, but it will also lose value twice as fast if the price of mETH falls. If the price of mETH falls too far, the position will be liquidated. Another source of risk is if the rate to borrow USDT remains high for an extended period, increasing the value of your debt and potentially leading to liquidation. Additionally, smart contract risk is always present.`,
  },
  {
    id: '6',
    title: 'Leveraged mETH',
    tags: [
      Tags.leverage4x,
      Tags.rewardsMax,
      Tags.ethExposure4x,
      Tags.ethBull,
      Tags.lst,
      Tags.minerals,
      Tags.eigenLayer,
    ],
    collateralAssets: [METH[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    debtAssets: [USDT[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    risk: Risk.high,
    riskAssessment: `This is a high risk strategy. This strategy has 4x exposure to the price of ETH, so while increases in price are amplified, so are decreases in price, introducing the possibility of liquidation if the price drops.`,
    leverage: 4,
    includeRates: ['ETH Staking APR', 'ETH Staking APY'],
    chain: ChainId.MANTLE,
    supplyOraclePath: ['Chronicle (Market Price)'],
    supplyOracleType: OracleType.chronicle,
    borrowOraclePath: ['Chronicle (Market Price)'],
    borrowOracleType: OracleType.chronicle,
    description: `This strategy utilizes mETH as collateral in order to borrow USDT, and swaps that USDT into more mETH collateral, resulting in leveraged exposure to mETH. This strategy has 4x exposure to the price of mETH. This strategy earns interest in the form of mETH, as well as points.`,
    whyItWorks: `This strategy is designed for users who are very bullish on ETH, and specifically mETH. The strategy earns leveraged interest in the form of mETH, earns leveraged points, and has 4x exposure to the price of mETH. As a result, even if the rate to borrow USDT is higher than the yield earned on mETH, the strategy can still be profitable if the price of mETH increases.`,
    risks: `This strategy has 4x exposure to the price of mETH, so your position will gain value 4x as fast as the price of mETH increases, but it will also lose value 4x as fast if the price of mETH falls. If the price of mETH falls too far, the position will be liquidated. Another source of risk is if the rate to borrow USDT remains high for an extended period, increasing the value of your debt and potentially leading to liquidation. Additionally, smart contract risk is always present.`,
  },
  {
    id: '7',
    title: 'Long wstETH / Short UNI',
    tags: [Tags.leverage2x, Tags.yieldMax, Tags.leveragedExposure, Tags.ethBull, Tags.ethStaking, Tags.lst],
    collateralAssets: [WST_ETH[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [UNI[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. In the recent past, UNI has roughly approximated ETH's price, so while looping UNI into ETH isn't a delta-neutral strategy, historically it hasn't had the same comparative volatility to a leveraged ETH position against USD. This strategy utilizes only 2x leverage, reducing - but not eliminating - risk.`,
    leverage: 2,
    includeRates: ['ETH Staking APR', 'ETH Staking APY'],
    chain: ChainId.ARBITRUM_ONE,
    supplyOraclePath: ['Chronicle (Market Price)'],
    supplyOracleType: OracleType.chronicle,
    borrowOraclePath: ['Chronicle (Market Price)'],
    borrowOracleType: OracleType.chronicle,
    description: `This strategy utilizes wstETH as collateral in order to borrow UNI, and swaps that UNI into more wstETH collateral, resulting in leveraged exposure to wstETH. This strategy has 2x exposure to the price of wstETH in terms of UNI, meaning the strategy gains value when the USD price of wstETH rises faster than the USD price of UNI. This strategy earns interest in the form of wstETH.`,
    whyItWorks: `This strategy has two aspects at play. First is that UNI generally has a low borrow interest rate, so the resulting loop into wstETH can be high yielding in terms of ETH if wstETH and UNI maintain their prices. Second is that this strategy gains value when the price of wstETH rises faster than the price of UNI - or put differently, increases in terms of UNI - which is a fit for someone bullish on ETH against UNI.`,
    risks: `This strategy has several risks. Leveraged exposure means that if the price of ETH falls too far compared to UNI, the position will be liquidated. There's also a risk that the interest rate to borrow UNI climbs above the yield on wstETH, resulting in the position being negative yielding. Additionally, smart contract risk is always present.`,
  },
  {
    id: '8',
    title: 'Long wstETH / Short UNI',
    tags: [Tags.leverage3x, Tags.yieldMax, Tags.leveragedExposure, Tags.ethBull, Tags.ethStaking, Tags.lst],
    collateralAssets: [WST_ETH[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [UNI[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.high,
    riskAssessment: `This is a high risk strategy. In the recent past, UNI has roughly approximated ETH's price, so while looping UNI into ETH isn't a delta-neutral strategy, historically it hasn't had the same comparative volatility to a leveraged ETH position against USD. This strategy however utilizes 3x leverage, so moderate drops in the price of ETH compared to UNI could result in a liquidation.`,
    leverage: 3,
    includeRates: ['ETH Staking APR', 'ETH Staking APY'],
    chain: ChainId.ARBITRUM_ONE,
    supplyOraclePath: ['Chronicle (Market Price)'],
    supplyOracleType: OracleType.chronicle,
    borrowOraclePath: ['Chronicle (Market Price)'],
    borrowOracleType: OracleType.chronicle,
    description: `This strategy utilizes wstETH as collateral in order to borrow UNI, and swaps that UNI into more wstETH collateral, resulting in leveraged exposure to wstETH. This strategy has 3x exposure to the price of wstETH in terms of UNI, meaning the strategy gains value when the USD price of wstETH rises faster than the USD price of UNI. This strategy earns interest in the form of wstETH.`,
    whyItWorks: `This strategy has two aspects at play. First is that UNI generally has a low borrow interest rate, so the resulting loop into wstETH can be high yielding in terms of ETH if wstETH and UNI maintain their prices. Second is that this strategy gains value when the price of wstETH rises faster than the price of UNI - or put differently, increases in terms of UNI - which is a fit for someone bullish on ETH against UNI.`,
    risks: `This strategy has several risks. This strategy has a higher leverage, and leveraged exposure means that if the price of ETH falls too far compared to UNI, the position will be liquidated. You will need to keep an eye on the health factor to ensure that it stays above 1. There's also a risk that the interest rate to borrow UNI climbs above the yield earned on wstETH, resulting in the position being negative yielding. Additionally, smart contract risk is always present.`,
  },
  {
    id: '9',
    title: 'USDC Rate Arbitrage',
    tags: [Tags.leverage4x, Tags.yieldMax, Tags.deltaNeutral, Tags.minerals],
    collateralAssets: [USDC[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [GRAI[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. USDC and GRAI are both stablecoins, which mitigates risk from price fluctuations. However with the higher leverage of this strategy, extended periods of high borrow rates can push this strategy towards liquidation. Additionally there is always the risk of a depeg.`,
    leverage: 4,
    includeRates: ['ETH Staking APR', 'ETH Staking APY'],
    chain: ChainId.ARBITRUM_ONE,
    supplyOraclePath: ['Chainlink (Market Price)'],
    supplyOracleType: OracleType.chainlink,
    borrowOraclePath: ['Ramses TWAP'],
    borrowOracleType: OracleType.other,
    description: `This strategy utilizes USDC as collateral in order to borrow GRAI, and swaps that GRAI into more USDC collateral, "looping" USDC. This strategy is delta-neutral, as both the collateral and debt are stablecoins. This strategy earns interest in the form of USDC, as well as USDC rewards.`,
    whyItWorks: `This strategy works because the yield on USDC at times is higher than the rate to borrow GRAI when there is high demand to borrow USDC. GRAI and USDC are both stablecoins, so the strategy doesn't have any intrinsic price exposure.`,
    risks: `While USDC earns a higher rate of return when there is high utilization of USDC on Dolomite, that isn't always the case. As a result, if the rate to borrow GRAI remains higher than the yield earned by supplying USDC, this strategy can be negative yielding, and with too much negative yield can be pushed to liquidation. Additionally, risk of depeg and smart contract risk are always present.`,
    disabled: true,
  },
  {
    id: '10',
    title: 'USDC.e Rate Arbitrage',
    tags: [Tags.leverage4x, Tags.yieldMax, Tags.deltaNeutral],
    collateralAssets: [BRIDGED_USDC[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [GRAI[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. USDC.e and GRAI are both stablecoins, which mitigates risk from price fluctuations. However with the higher leverage of this strategy, extended periods of high borrow rates can push this strategy towards liquidation. Additionally there is always the risk of a depeg.`,
    leverage: 4,
    includeRates: ['ETH Staking APR', 'ETH Staking APY'],
    chain: ChainId.ARBITRUM_ONE,
    supplyOraclePath: ['Chainlink (Market Price)'],
    supplyOracleType: OracleType.chainlink,
    borrowOraclePath: ['Ramses TWAP'],
    borrowOracleType: OracleType.other,
    description: `This strategy utilizes USDC.e as collateral in order to borrow GRAI, and swaps that GRAI into more USDC.e collateral, "looping" USDC.e. This strategy is delta-neutral, as both the collateral and debt are stablecoins. This strategy earns interest in the form of USDC.e.`,
    whyItWorks: `This strategy works because the yield on USDC.e at times is higher than the rate to borrow GRAI when there is high demand to borrow USDC.e. GRAI and USDC.e are both stablecoins, so the strategy doesn't have any intrinsic price exposure.`,
    risks: `While USDC.e earns a higher rate of return when there is high utilization of USDC.e on Dolomite, that isn't always the case. As a result, if the rate to borrow GRAI remains higher than the yield earned by supplying USD.eC, this strategy can be negative yielding, and with too much negative yield can be pushed to liquidation. Additionally, risk of depeg and smart contract risk are always present.`,
    disabled: true,
  },
  {
    id: '11',
    title: 'Tether Rate Arbitrage',
    tags: [Tags.leverage4x, Tags.yieldMax, Tags.deltaNeutral],
    collateralAssets: [USDT[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [GRAI[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. USDT and GRAI are both stablecoins, which mitigates risk from price fluctuations. However with the higher leverage of this strategy, extended periods of high borrow rates can push this strategy towards liquidation. Additionally there is always the risk of a depeg.`,
    leverage: 4,
    includeRates: ['ETH Staking APR', 'ETH Staking APY'],
    chain: ChainId.ARBITRUM_ONE,
    supplyOraclePath: ['Chainlink (Market Price)'],
    supplyOracleType: OracleType.chainlink,
    borrowOraclePath: ['Ramses TWAP'],
    borrowOracleType: OracleType.other,
    description: `This strategy utilizes USDT as collateral in order to borrow GRAI, and swaps that GRAI into more USDT collateral, "looping" USDT. This strategy is delta-neutral, as both the collateral and debt are stablecoins. This strategy earns interest in the form of USDT.`,
    whyItWorks: `This strategy works because the yield on USDT at times is higher than the rate to borrow GRAI when there is high demand to borrow USDT. GRAI and USDT are both stablecoins, so the strategy doesn't have any intrinsic price exposure.`,
    risks: `While USDT earns a higher rate of return when there is high utilization of USDT on Dolomite, that isn't always the case. As a result, if the rate to borrow GRAI remains higher than the yield earned by supplying USDT, this strategy can be negative yielding, and with too much negative yield can be pushed to liquidation. Additionally, risk of depeg and smart contract risk are always present.`,
    disabled: true,
  },
  /*{
    id: '9',
    title: 'Looped sGMX',
    tags: [Tags.leverage3x, Tags.yieldMax, Tags.rewardsMax],
    collateralAssets: [S_GMX ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [GMX[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.low,
    riskAssessment: `This is a high risk strategy. In the recent past, UNI has roughly approximated ETH's price, so while looping UNI into ETH isn't a delta-neutral strategy, historically it hasn't had the same comparative volatility to a leveraged ETH position against USD. This strategy however utilizes 3x leverage, so moderate drops in the price of ETH compared to UNI could result in a liquidation.`,
    leverage: 3,
    includeRates: ['ETH Base Yield', 'ETH Boosted Yield', 'esGMX Yield'],
    chain: ChainId.ARBITRUM_ONE,
    supplyOraclePath: ['Chronicle (Market Price)'],
    supplyOracleType: OracleType.chronicle,
    borrowOraclePath: ['Chronicle (Market Price)'],
    borrowOracleType: OracleType.chronicle,
    description: `This strategy utilizes wstETH as collateral in order to borrow UNI, and swaps that UNI into more wstETH collateral, resulting in leveraged exposure to wstETH. This strategy has 3x exposure to the price of wstETH in terms of UNI, meaning the strategy gains value when the USD price of wstETH rises faster than the USD price of UNI. This strategy earns interest in the form of wstETH.`,
    whyItWorks: `This strategy has two aspects at play. First is that UNI generally has a low borrow interest rate, so the resulting loop into wstETH can be high yielding in terms of ETH if wstETH and UNI maintain their prices. Second is that this strategy gains value when the price of wstETH rises faster than the price of UNI - or put differently, increases in terms of UNI - which is a fit for someone bullish on ETH against UNI.`,
    risks: `This strategy has several risks. This strategy has a higher leverage, and leveraged exposure means that if the price of ETH falls too far compared to UNI, the position will be liquidated. You will need to keep an eye on the health factor to ensure that it stays above 1. There's also a risk that the interest rate to borrow UNI climbs above the yield earned on wstETH, resulting in the position being negative yielding. Additionally, smart contract risk is always present.`,
  },
  {
    id: '10',
    title: 'Looped ezETH',
    tags: [Tags.leverage5x, Tags.yieldMax, Tags.lst, Tags.ethExposure1x, Tags.ethBull],
    collateralAssets: [EZ_ETH[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.high,
    riskAssessment: `This is a high risk strategy. In the recent past, UNI has roughly approximated ETH's price, so while looping UNI into ETH isn't a delta-neutral strategy, historically it hasn't had the same comparative volatility to a leveraged ETH position against USD. This strategy however utilizes 3x leverage, so moderate drops in the price of ETH compared to UNI could result in a liquidation.`,
    leverage: 5,
    includeRates: ['ETH Base Yield', 'ETH Boosted Yield', 'esGMX Yield'],
    chain: ChainId.ARBITRUM_ONE,
    supplyOraclePath: ['Chronicle (Market Price)'],
    supplyOracleType: OracleType.chronicle,
    borrowOraclePath: ['Chronicle (Market Price)'],
    borrowOracleType: OracleType.chronicle,
    description: `This strategy utilizes wstETH as collateral in order to borrow UNI, and swaps that UNI into more wstETH collateral, resulting in leveraged exposure to wstETH. This strategy has 3x exposure to the price of wstETH in terms of UNI, meaning the strategy gains value when the USD price of wstETH rises faster than the USD price of UNI. This strategy earns interest in the form of wstETH.`,
    whyItWorks: `This strategy has two aspects at play. First is that UNI generally has a low borrow interest rate, so the resulting loop into wstETH can be high yielding in terms of ETH if wstETH and UNI maintain their prices. Second is that this strategy gains value when the price of wstETH rises faster than the price of UNI - or put differently, increases in terms of UNI - which is a fit for someone bullish on ETH against UNI.`,
    risks: `This strategy has several risks. This strategy has a higher leverage, and leveraged exposure means that if the price of ETH falls too far compared to UNI, the position will be liquidated. You will need to keep an eye on the health factor to ensure that it stays above 1. There's also a risk that the interest rate to borrow UNI climbs above the yield earned on wstETH, resulting in the position being negative yielding. Additionally, smart contract risk is always present.`,
  },
  {
    id: '11',
    title: 'Leveraged RDNT',
    tags: [Tags.leverage2x, Tags.yieldMax, Tags.leveragedExposure],
    collateralAssets: [RDNT[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [GRAI[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.high,
    riskAssessment: `This is a high risk strategy. In the recent past, UNI has roughly approximated ETH's price, so while looping UNI into ETH isn't a delta-neutral strategy, historically it hasn't had the same comparative volatility to a leveraged ETH position against USD. This strategy however utilizes 3x leverage, so moderate drops in the price of ETH compared to UNI could result in a liquidation.`,
    leverage: 2,
    includeRates: ['ETH Base Yield', 'ETH Boosted Yield', 'esGMX Yield'],
    chain: ChainId.ARBITRUM_ONE,
    supplyOraclePath: ['Chronicle (Market Price)'],
    supplyOracleType: OracleType.chronicle,
    borrowOraclePath: ['Chronicle (Market Price)'],
    borrowOracleType: OracleType.chronicle,
    description: `This strategy utilizes wstETH as collateral in order to borrow UNI, and swaps that UNI into more wstETH collateral, resulting in leveraged exposure to wstETH. This strategy has 3x exposure to the price of wstETH in terms of UNI, meaning the strategy gains value when the USD price of wstETH rises faster than the USD price of UNI. This strategy earns interest in the form of wstETH.`,
    whyItWorks: `This strategy has two aspects at play. First is that UNI generally has a low borrow interest rate, so the resulting loop into wstETH can be high yielding in terms of ETH if wstETH and UNI maintain their prices. Second is that this strategy gains value when the price of wstETH rises faster than the price of UNI - or put differently, increases in terms of UNI - which is a fit for someone bullish on ETH against UNI.`,
    risks: `This strategy has several risks. This strategy has a higher leverage, and leveraged exposure means that if the price of ETH falls too far compared to UNI, the position will be liquidated. You will need to keep an eye on the health factor to ensure that it stays above 1. There's also a risk that the interest rate to borrow UNI climbs above the yield earned on wstETH, resulting in the position being negative yielding. Additionally, smart contract risk is always present.`,
  },
  {
    id: '12',
    title: 'Looped USDC',
    tags: [Tags.leverage4x, Tags.yieldMax, Tags.deltaNeutral, Tags.minerals],
    collateralAssets: [USDC[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [GRAI[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.medium,
    riskAssessment: `This is a high risk strategy. In the recent past, UNI has roughly approximated ETH's price, so while looping UNI into ETH isn't a delta-neutral strategy, historically it hasn't had the same comparative volatility to a leveraged ETH position against USD. This strategy however utilizes 3x leverage, so moderate drops in the price of ETH compared to UNI could result in a liquidation.`,
    leverage: 4,
    includeRates: ['ETH Base Yield', 'ETH Boosted Yield', 'esGMX Yield'],
    chain: ChainId.ARBITRUM_ONE,
    supplyOraclePath: ['Chronicle (Market Price)'],
    supplyOracleType: OracleType.chronicle,
    borrowOraclePath: ['Chronicle (Market Price)'],
    borrowOracleType: OracleType.chronicle,
    description: `This strategy utilizes wstETH as collateral in order to borrow UNI, and swaps that UNI into more wstETH collateral, resulting in leveraged exposure to wstETH. This strategy has 3x exposure to the price of wstETH in terms of UNI, meaning the strategy gains value when the USD price of wstETH rises faster than the USD price of UNI. This strategy earns interest in the form of wstETH.`,
    whyItWorks: `This strategy has two aspects at play. First is that UNI generally has a low borrow interest rate, so the resulting loop into wstETH can be high yielding in terms of ETH if wstETH and UNI maintain their prices. Second is that this strategy gains value when the price of wstETH rises faster than the price of UNI - or put differently, increases in terms of UNI - which is a fit for someone bullish on ETH against UNI.`,
    risks: `This strategy has several risks. This strategy has a higher leverage, and leveraged exposure means that if the price of ETH falls too far compared to UNI, the position will be liquidated. You will need to keep an eye on the health factor to ensure that it stays above 1. There's also a risk that the interest rate to borrow UNI climbs above the yield earned on wstETH, resulting in the position being negative yielding. Additionally, smart contract risk is always present.`,
  },
  {
    id: '13',
    title: 'Looped PT-USDe',
    tags: [Tags.leverage4x, Tags.yieldMax, Tags.deltaNeutral, Tags.minerals],
    collateralAssets: [PT_USDE ?? WETH[ChainId.MANTLE]],
    debtAssets: [USDE[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    risk: Risk.medium,
    riskAssessment: `This is a high risk strategy. In the recent past, UNI has roughly approximated ETH's price, so while looping UNI into ETH isn't a delta-neutral strategy, historically it hasn't had the same comparative volatility to a leveraged ETH position against USD. This strategy however utilizes 3x leverage, so moderate drops in the price of ETH compared to UNI could result in a liquidation.`,
    leverage: 4,
    includeRates: ['ETH Base Yield', 'ETH Boosted Yield', 'esGMX Yield'],
    chain: ChainId.MANTLE,
    supplyOraclePath: ['Chronicle (Market Price)'],
    supplyOracleType: OracleType.chronicle,
    borrowOraclePath: ['Chronicle (Market Price)'],
    borrowOracleType: OracleType.chronicle,
    description: `This strategy utilizes wstETH as collateral in order to borrow UNI, and swaps that UNI into more wstETH collateral, resulting in leveraged exposure to wstETH. This strategy has 3x exposure to the price of wstETH in terms of UNI, meaning the strategy gains value when the USD price of wstETH rises faster than the USD price of UNI. This strategy earns interest in the form of wstETH.`,
    whyItWorks: `This strategy has two aspects at play. First is that UNI generally has a low borrow interest rate, so the resulting loop into wstETH can be high yielding in terms of ETH if wstETH and UNI maintain their prices. Second is that this strategy gains value when the price of wstETH rises faster than the price of UNI - or put differently, increases in terms of UNI - which is a fit for someone bullish on ETH against UNI.`,
    risks: `This strategy has several risks. This strategy has a higher leverage, and leveraged exposure means that if the price of ETH falls too far compared to UNI, the position will be liquidated. You will need to keep an eye on the health factor to ensure that it stays above 1. There's also a risk that the interest rate to borrow UNI climbs above the yield earned on wstETH, resulting in the position being negative yielding. Additionally, smart contract risk is always present.`,
  },
  /*{
    id: '9',
    title: 'Looped gmETH',
    tags: [Tags.leverage5x, Tags.yieldMax, Tags.ethBull],
    collateralAssets: [GM_ETH ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.medium,
    riskAssessment: `This is a high risk strategy. In the recent past, UNI has roughly approximated ETH's price, so while looping UNI into ETH isn't a delta-neutral strategy, historically it hasn't had the same comparative volatility to a leveraged ETH position against USD. This strategy however utilizes 3x leverage, so moderate drops in the price of ETH compared to UNI could result in a liquidation.`,
    leverage: 5,
    includeRates: ['GM Fees APR', 'GM Fees APY'],
    chain: ChainId.ARBITRUM_ONE,
    supplyOraclePath: ['Chronicle (Market Price)'],
    supplyOracleType: OracleType.chronicle,
    borrowOraclePath: ['Chronicle (Market Price)'],
    borrowOracleType: OracleType.chronicle,
    description: `This strategy utilizes wstETH as collateral in order to borrow UNI, and swaps that UNI into more wstETH collateral, resulting in leveraged exposure to wstETH. This strategy has 3x exposure to the price of wstETH in terms of UNI, meaning the strategy gains value when the USD price of wstETH rises faster than the USD price of UNI. This strategy earns interest in the form of wstETH.`,
    whyItWorks: `This strategy has two aspects at play. First is that UNI generally has a low borrow interest rate, so the resulting loop into wstETH can be high yielding in terms of ETH if wstETH and UNI maintain their prices. Second is that this strategy gains value when the price of wstETH rises faster than the price of UNI - or put differently, increases in terms of UNI - which is a fit for someone bullish on ETH against UNI.`,
    risks: `This strategy has several risks. This strategy has a higher leverage, and leveraged exposure means that if the price of ETH falls too far compared to UNI, the position will be liquidated. You will need to keep an eye on the health factor to ensure that it stays above 1. There's also a risk that the interest rate to borrow UNI climbs above the yield earned on wstETH, resulting in the position being negative yielding. Additionally, smart contract risk is always present.`,
  },*/
  /*{
    id: '1',
    title: 'Looped rsETH',
    tags: [Tags.leverage2x, Tags.rewardsMax, Tags.ethExposure1x, Tags.lrt, Tags.ethStaking, Tags.kelp, Tags.eigenLayer],
    collateralAssets: [RS_ETH[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.low,
    riskAssessment: `This is a lower risk strategy due to the correlated price of the collateral and the debt. Some risks that do exist are smart contract risk as well as the risk that borrow rates on ETH climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 2,
    includeRates: ['ETH Restaking APR', 'ETH Restaking APY'],
    chain: ChainId.ARBITRUM_ONE,
  },
  {
    id: '2',
    title: 'Looped rsETH',
    tags: [Tags.leverage4x, Tags.rewardsMax, Tags.ethExposure1x, Tags.lrt, Tags.ethStaking, Tags.kelp, Tags.eigenLayer],
    collateralAssets: [RS_ETH[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. The risk is limited due to the correlated price of the collateral and the debt, however the strategy utilizes high leverage. Some risks that do exist are smart contract risk as well as the risk that borrow rates on ETH climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 4,
    includeRates: ['ETH Restaking APR', 'ETH Restaking APY'],
    chain: ChainId.ARBITRUM_ONE,
  },
  {
    id: '3',
    title: 'Looped PT-mETH',
    tags: [Tags.leverage3x, Tags.yieldMax, Tags.ethExposure1x, Tags.ethStaking, Tags.pendle, Tags.expirable],
    collateralAssets: [PT_METH ?? WETH[ChainId.MANTLE]],
    debtAssets: [METH[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    risk: Risk.low,
    riskAssessment: `This is a lower risk strategy due to the correlated price of the collateral and the debt. Some risks that do exist are smart contract risk as well as the risk that borrow rates on ETH climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 3,
    chain: ChainId.MANTLE,
    includeRates: ['Pendle Fixed APR', 'ETH Staking APR'],
    specialTag: 'Featured',
  },
  {
    id: '4',
    title: 'Looped PT-mETH',
    tags: [Tags.leverage5x, Tags.yieldMax, Tags.ethExposure1x, Tags.ethStaking, Tags.pendle, Tags.expirable],
    collateralAssets: [PT_METH ?? WETH[ChainId.MANTLE]],
    debtAssets: [METH[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. The risk is limited due to the correlated price of the collateral and the debt, however the strategy utilizes high leverage. Some risks that do exist are smart contract risk as well as the risk that borrow rates on ETH climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 5,
    chain: ChainId.MANTLE,
    includeRates: ['Pendle Fixed APR', 'ETH Staking APR'],
    specialTag: 'Featured',
  },
  {
    id: '5',
    title: 'Looped GRAI',
    tags: [Tags.leverage2x, Tags.yieldMax, Tags.rewardsMax, Tags.deltaNeutral, Tags.ascend, Tags.goArb],
    collateralAssets: [GRAI[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [USDC[ChainId.ARBITRUM_ONE]],
    risk: Risk.low,
    riskAssessment: `This is a lower risk strategy due to the correlated price of the debt and collateral. Some risks that do exist are smart contract risk as well as the risk that borrow rates on USDC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 2,
    chain: ChainId.ARBITRUM_ONE,
  },
  {
    id: '6',
    title: 'Looped GRAI',
    tags: [Tags.leverage4x, Tags.yieldMax, Tags.rewardsMax, Tags.deltaNeutral, Tags.ascend, Tags.goArb],
    collateralAssets: [GRAI[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [USDC[ChainId.ARBITRUM_ONE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. The correlated price of the strategy's debt and collateral helps to reduce risk, however this strategy uses higher leverage. Some risks that do exist are smart contract risk as well as the risk that borrow rates on USDC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 4,
    chain: ChainId.ARBITRUM_ONE,
  },
  {
    id: '7',
    title: 'Looped USDe',
    tags: [Tags.leverage3x, Tags.yieldMax, Tags.deltaNeutral],
    collateralAssets: [USDE[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    debtAssets: [USDT[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    risk: Risk.medium,
    riskAssessment: `This is a lower risk strategy due to the correlated price of the debt and collateral. Some risks that do exist are smart contract risk as well as the risk that borrow rates on USDC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 3,
    chain: ChainId.MANTLE,
  },
  {
    id: '8',
    title: 'Looped USDe',
    tags: [Tags.leverage5x, Tags.yieldMax, Tags.deltaNeutral],
    collateralAssets: [USDE[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    debtAssets: [USDT[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. The correlated price of the strategy's debt and collateral helps to reduce risk, however this strategy uses higher leverage. Some risks that do exist are smart contract risk as well as the risk that borrow rates on USDC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 5,
    chain: ChainId.MANTLE,
  },
  {
    id: '9',
    title: 'Looped USDe',
    tags: [Tags.leverage3x, Tags.yieldMax, Tags.deltaNeutral],
    collateralAssets: [USDE[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    debtAssets: [USDC[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    risk: Risk.medium,
    riskAssessment: `This is a lower risk strategy due to the correlated price of the debt and collateral. Some risks that do exist are smart contract risk as well as the risk that borrow rates on USDC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 3,
    chain: ChainId.MANTLE,
  },
  {
    id: '10',
    title: 'Looped USDe',
    tags: [Tags.leverage5x, Tags.yieldMax, Tags.deltaNeutral],
    collateralAssets: [USDE[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    debtAssets: [USDC[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. The correlated price of the strategy's debt and collateral helps to reduce risk, however this strategy uses higher leverage. Some risks that do exist are smart contract risk as well as the risk that borrow rates on USDC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 5,
    chain: ChainId.MANTLE,
  },
  {
    id: '11',
    title: 'Looped PT-USDe',
    tags: [Tags.leverage3x, Tags.yieldMax, Tags.deltaNeutral],
    collateralAssets: [PT_USDE ?? WETH[ChainId.MANTLE]],
    debtAssets: [USDT[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    risk: Risk.medium,
    riskAssessment: `This is a lower risk strategy due to the correlated price of the debt and collateral. Some risks that do exist are smart contract risk as well as the risk that borrow rates on USDC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 3,
    chain: ChainId.MANTLE,
  },
  {
    id: '12',
    title: 'Looped PT-USDe',
    tags: [Tags.leverage5x, Tags.yieldMax, Tags.deltaNeutral],
    collateralAssets: [PT_USDE ?? WETH[ChainId.MANTLE]],
    debtAssets: [USDT[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. The correlated price of the strategy's debt and collateral helps to reduce risk, however this strategy uses higher leverage. Some risks that do exist are smart contract risk as well as the risk that borrow rates on USDC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 5,
    chain: ChainId.MANTLE,
  },
  {
    id: '13',
    title: 'Looped PT-USDe',
    tags: [Tags.leverage3x, Tags.yieldMax, Tags.deltaNeutral],
    collateralAssets: [PT_USDE ?? WETH[ChainId.MANTLE]],
    debtAssets: [USDC[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    risk: Risk.medium,
    riskAssessment: `This is a lower risk strategy due to the correlated price of the debt and collateral. Some risks that do exist are smart contract risk as well as the risk that borrow rates on USDC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 3,
    chain: ChainId.MANTLE,
  },
  {
    id: '14',
    title: 'Looped PT-USDe',
    tags: [Tags.leverage5x, Tags.yieldMax, Tags.deltaNeutral],
    collateralAssets: [PT_USDE ?? WETH[ChainId.MANTLE]],
    debtAssets: [USDC[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. The correlated price of the strategy's debt and collateral helps to reduce risk, however this strategy uses higher leverage. Some risks that do exist are smart contract risk as well as the risk that borrow rates on USDC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 5,
    chain: ChainId.MANTLE,
  },
  {
    id: '15',
    title: 'Looped weETH / ezETH',
    tags: [Tags.leverage2x, Tags.yieldMax, Tags.ethExposure1x, Tags.ezPoints, Tags.eigenLayer],
    collateralAssets: [EZ_ETH[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [WE_ETH[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.medium,
    riskAssessment: `This is a lower risk strategy due to the correlated price of the debt and collateral. Some risks that do exist are smart contract risk as well as the risk that borrow rates on USDC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 2,
    includeRates: ['ETH Restaking APR', 'ETH Restaking APY'],
    chain: ChainId.ARBITRUM_ONE,
  },
  {
    id: '16',
    title: 'Looped weETH / ezETH',
    tags: [Tags.leverage4x, Tags.yieldMax, Tags.ethExposure1x, Tags.ezPoints, Tags.eigenLayer],
    collateralAssets: [EZ_ETH[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [WE_ETH[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. The correlated price of the strategy's debt and collateral helps to reduce risk, however this strategy uses higher leverage. Some risks that do exist are smart contract risk as well as the risk that borrow rates on USDC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 4,
    includeRates: ['ETH Restaking APR', 'ETH Restaking APY'],
    chain: ChainId.ARBITRUM_ONE,
  },
  {
    id: '17',
    title: 'Looped weETH / rsETH',
    tags: [Tags.leverage2x, Tags.yieldMax, Tags.ethExposure1x, Tags.kelp, Tags.eigenLayer],
    collateralAssets: [RS_ETH[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [WE_ETH[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.medium,
    riskAssessment: `This is a lower risk strategy due to the correlated price of the debt and collateral. Some risks that do exist are smart contract risk as well as the risk that borrow rates on USDC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 2,
    includeRates: ['ETH Restaking APR', 'ETH Restaking APY'],
    chain: ChainId.ARBITRUM_ONE,
  },
  {
    id: '18',
    title: 'Looped weETH / rsETH',
    tags: [Tags.leverage4x, Tags.yieldMax, Tags.ethExposure1x, Tags.kelp, Tags.eigenLayer],
    collateralAssets: [RS_ETH[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [WE_ETH[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. The correlated price of the strategy's debt and collateral helps to reduce risk, however this strategy uses higher leverage. Some risks that do exist are smart contract risk as well as the risk that borrow rates on USDC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 4,
    includeRates: ['ETH Restaking APR', 'ETH Restaking APY'],
    chain: ChainId.ARBITRUM_ONE,
  },
  {
    id: '19',
    title: 'Long wstETH / Short UNI',
    tags: [Tags.leverage2x, Tags.yieldMax, Tags.lst],
    collateralAssets: [WST_ETH[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [UNI[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. In the recent past, UNI has roughly approximated ETH's price, so while looping UNI into ETH isn't a delta-neutral strategy, historically it hasn't had the same comparative volatility to a leveraged ETH position against USD. This strategy utilizes only 2x leverage, reducing - but not eliminating - risk.`,
    leverage: 2,
    includeRates: ['ETH Staking APR', 'ETH Staking APY'],
    chain: ChainId.ARBITRUM_ONE,
  },
  {
    id: '20',
    title: 'Long wstETH / Short UNI',
    tags: [Tags.leverage4x, Tags.yieldMax, Tags.lst],
    collateralAssets: [WST_ETH[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [UNI[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    risk: Risk.high,
    riskAssessment: `This is a high risk strategy. In the recent past, UNI has roughly approximated ETH's price, so while looping UNI into ETH isn't a delta-neutral strategy, historically it hasn't had the same comparative volatility to a leveraged ETH position against USD. This strategy however utilizes 4x leverage, so moderate drops in the price of ETH compared to UNI could result in a liquidation.`,
    leverage: 4,
    includeRates: ['ETH Staking APR', 'ETH Staking APY'],
    chain: ChainId.ARBITRUM_ONE,
  },
  {
    id: '21',
    title: 'Leveraged mETH',
    tags: [Tags.leverage2x, Tags.rewardsMax, Tags.ethExposure2x, Tags.powder, Tags.minerals, Tags.eigenLayer],
    collateralAssets: [METH[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    debtAssets: [USDT[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. This strategy has 2x exposure to the price of ETH, so while increases in price are amplified, so are decreases in price, introducing the possibility of liquidation if the price drops too far.`,
    leverage: 2,
    includeRates: [],
    chain: ChainId.MANTLE,
  },
  {
    id: '22',
    title: 'Leveraged mETH',
    tags: [Tags.leverage4x, Tags.rewardsMax, Tags.ethExposure4x, Tags.powder, Tags.minerals, Tags.eigenLayer],
    collateralAssets: [METH[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    debtAssets: [USDT[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    risk: Risk.high,
    riskAssessment: `This is a high risk strategy. This strategy has 4x exposure to the price of ETH, so while increases in price are amplified, so are decreases in price, introducing the possibility of liquidation if the price drops.`,
    leverage: 4,
    includeRates: [],
    chain: ChainId.MANTLE,
  },*/
  /*{
    id: '4',
    title: 'Looped mETH',
    tags: ['rewardsMax', Tags.leverage3x, 'minerals', 'powder', Tags.ethStaking, Tags.eigenLayer],
    collateralAssets: [METH[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    debtAssets: [WETH[ChainId.MANTLE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. Risk is partially limited due to the correlated price of the collateral and the debt, however it is very possible that the strategy has negative yield, and given enough time could trend towards liquidation. Some risks that do exist are smart contract risk as well as the risk that borrow rates on ETH climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 3,
    chain: ChainId.MANTLE,
    includeRates: ['ETH Staking APR'],
  },
  {
    id: '5',
    title: 'Looped mETH',
    tags: [Tags.leverage5x, 'minerals', 'powder', Tags.ethStaking, Tags.eigenLayer],
    collateralAssets: [METH[ChainId.MANTLE] ?? WETH[ChainId.MANTLE]],
    debtAssets: [WETH[ChainId.MANTLE]],
    risk: Risk.high,
    riskAssessment: `This is a high risk strategy. Risk is partially limited due to the correlated price of the collateral and the debt, however this strategy uses high leverage and it is very possible that the strategy has negative yield, and given enough time could trend towards liquidation. Some risks that do exist are smart contract risk as well as the risk that borrow rates on ETH climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 5,
    chain: ChainId.MANTLE,
    includeRates: ['ETH Staking APR'],
  },
  {
    id: '8',
    title: 'Looped wUSDM',
    tags: ['leverage2x', Tags.yieldMax, Tags.deltaNeutral, 'usdm', 'oArb'],
    collateralAssets: [W_USDM[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [USDC[ChainId.ARBITRUM_ONE]],
    risk: Risk.low,
    riskAssessment: `This is a lower risk strategy due to the correlated price of the debt and collateral. Some risks that do exist are smart contract risk as well as the risk that borrow rates on USDC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 2,
    chain: ChainId.ARBITRUM_ONE,
    includeRates: ['USDM APR'],
  },
  {
    id: '9',
    title: 'Looped wUSDM',
    tags: [Tags.leverage4x, Tags.yieldMax, Tags.deltaNeutral, 'oArb'],
    collateralAssets: [W_USDM[ChainId.ARBITRUM_ONE] ?? WETH[ChainId.ARBITRUM_ONE]],
    debtAssets: [USDC[ChainId.ARBITRUM_ONE]],
    risk: Risk.medium,
    riskAssessment: `This is a medium risk strategy. The correlated price of the strategy's debt and collateral helps to reduce risk, however this strategy uses higher leverage. Some risks that do exist are smart contract risk as well as the risk that borrow rates on USDC climb high for an extended period and cause the value of your debt to grow to the point of liquidation`,
    leverage: 4,
    chain: ChainId.ARBITRUM_ONE,
    includeRates: ['USDM APR'],
  },*/
]

export function useStrategyAmounts(
  strategy: StrategyInfo | undefined,
): [CurrencyAmount<Token>[], CurrencyAmount<Token>[]] {
  const leverage = strategy?.leverage ?? 1
  const [fiatPrices] = useFiatPricesWithLoadingIndicator(undefined, strategy?.chain, RefreshFrequency.Medium)
  const supplyAmounts = useMemo(
    () =>
      strategy?.collateralAssets.map(token => {
        const price = fiatPrices[token.address ?? '']
        const parsedAmountWithLeverage = tryParseAmount(leverage.toString(), token)
        return (
          parsedAmountWithLeverage?.divide(price?.greaterThan(0) ? price : ONE_FRACTION) ??
          CurrencyAmount.fromRawAmount(token, '0')
        )
      }),
    [fiatPrices, leverage, strategy?.collateralAssets],
  )
  const borrowAmounts = useMemo(
    () =>
      strategy?.debtAssets.map(token => {
        const price = fiatPrices[token.address ?? '']
        const parsedAmountWithLeverage = tryParseAmount((leverage - 1).toString(), token)
        return (
          parsedAmountWithLeverage?.divide(price?.greaterThan(0) ? price : ONE_FRACTION) ??
          CurrencyAmount.fromRawAmount(token, '0')
        )
      }),
    [fiatPrices, leverage, strategy?.debtAssets],
  )
  return useMemo(() => [supplyAmounts ?? [], borrowAmounts ?? []], [supplyAmounts, borrowAmounts])
}

export function useStrategiesAmounts(): [CurrencyAmount<Token>[][], CurrencyAmount<Token>[][]] {
  const { data: chainIdToTokenToFiatPriceMap } = useChainIdToTokenToFiatPriceMap(RefreshFrequency.Medium)
  const supplyAmounts = useMemo(
    () =>
      STRATEGIES_DATA.map(strategy =>
        strategy.collateralAssets.map(token => {
          const price = chainIdToTokenToFiatPriceMap[token.chainId as ChainId][token.address ?? '']
          const parsedAmountWithLeverage = tryParseAmount(strategy.leverage.toString(), token)
          return (
            parsedAmountWithLeverage?.divide(price?.greaterThan(0) ? price : ONE_FRACTION) ??
            CurrencyAmount.fromRawAmount(token, '0')
          )
        }),
      ),
    [chainIdToTokenToFiatPriceMap],
  )
  const borrowAmounts = useMemo(
    () =>
      STRATEGIES_DATA.map(strategy =>
        strategy.debtAssets.map(token => {
          const price = chainIdToTokenToFiatPriceMap[token.chainId as ChainId][token.address ?? '']
          const parsedAmountWithLeverage = tryParseAmount((strategy.leverage - 1).toString(), token)
          return (
            parsedAmountWithLeverage?.divide(price?.greaterThan(0) ? price : ONE_FRACTION) ??
            CurrencyAmount.fromRawAmount(token, '0')
          )
        }),
      ),
    [chainIdToTokenToFiatPriceMap],
  )
  return useMemo(() => [supplyAmounts, borrowAmounts], [supplyAmounts, borrowAmounts])
}

export function useStrategyData(): StrategyWithAmounts[] {
  const amountsForEachStrategy = useStrategiesAmounts()
  return useMemo(() => {
    return STRATEGIES_DATA.map((strategy, i) => {
      return {
        ...strategy,
        supplyAmounts: amountsForEachStrategy[0][i],
        borrowAmounts: amountsForEachStrategy[1][i],
      }
    })
  }, [amountsForEachStrategy])
}

function getStrategyInterestRateData(
  supplyAmounts: CurrencyAmount<Token>[],
  borrowAmounts: CurrencyAmount<Token>[],
  tokenToPriceMap: Record<string, Fraction | undefined>,
  tokenToInterestRateMap: Record<string, InterestRate | undefined>,
  includeRates: string[] | undefined,
): [Percent | undefined, Percent | undefined] {
  const totalRate = getStrategyInterestRate(supplyAmounts, borrowAmounts, tokenToPriceMap, tokenToInterestRateMap, true)
  const interestRate = getStrategyInterestRate(
    supplyAmounts,
    borrowAmounts,
    tokenToPriceMap,
    tokenToInterestRateMap,
    false,
    false,
    includeRates,
  )
  return [totalRate, interestRate]
}

export function useStrategyInterestRateMap(): Record<string, (Percent | undefined)[]> {
  const { data: chainToTokenToPricesMap } = useChainIdToTokenToFiatPriceMap()
  const { data: chainToTokenToInterestRateMap } = useChainIdToInterestRateMap()
  const amountsForEachStrategy = useStrategiesAmounts()

  return useMemo(() => {
    return STRATEGIES_DATA.map((t, i) => {
      const supplyAmounts = amountsForEachStrategy[0][i]
      const borrowAmounts = amountsForEachStrategy[1][i]
      return getStrategyInterestRateData(
        supplyAmounts,
        borrowAmounts,
        chainToTokenToPricesMap[t.chain],
        chainToTokenToInterestRateMap[t.chain],
        t.includeRates,
      )
    }).reduce((memo, calculatedData, i) => {
      memo[STRATEGIES_DATA[i].id] = calculatedData
      return memo
    }, {} as Record<string, (Percent | undefined)[]>)
  }, [amountsForEachStrategy, chainToTokenToInterestRateMap, chainToTokenToPricesMap])
}

export function getIndexOfPositionStrategy(position: BorrowPosition, strategies: StrategyWithAmounts[]) {
  const positionId = parseFloat(position?.id.split('-')[1])
  if (positionId >= STRATEGY_INDEX_LOWER && positionId <= STRATEGY_INDEX_UPPER) {
    const foundStrategy = strategies?.reduce((memo, strategy) => {
      memo[strategy.id] = false
      const matchingStrategy = strategy.supplyAmounts.map((supplyAmount, index) => {
        const positionSupply = position.supplyAmounts[index]
        const positionBorrow = position.borrowAmounts[index]
        if (!positionSupply || !positionBorrow) {
          return false
        }
        const sameToken =
          supplyAmount.currency.address.toUpperCase() === positionSupply.token.address.toUpperCase() &&
          strategy.borrowAmounts[index].currency.address.toUpperCase() === positionBorrow.token.address.toUpperCase()
        if (!sameToken) return false
        const positionRatio = positionSupply.amountTokenWei.divide(positionBorrow.amountTokenWei)
        const strategyRatio = supplyAmount.divide(strategy.borrowAmounts[index])
        return formatAmount(positionRatio) === formatAmount(strategyRatio)
      })
      if (matchingStrategy.includes(true)) memo[strategy.id] = true
      return memo
    }, {} as Record<string, boolean>)
    const index = Object.values(foundStrategy).indexOf(true)
    return index > -1 ? index : false
  }
  return false
}

export default STRATEGIES_DATA
