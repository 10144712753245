import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { ColumnTitles, ConnectBtn, ConnectWalletButton, DataRows, NoTrades } from './styled'
import { useTranslation } from 'react-i18next'
import Web3Status from '../../components/Web3Status'
import Column from '../../components/Orders/Column'
import DataRow from '../../components/Orders/DataRow'
import { useActiveWeb3React } from '../../hooks'
import { Fraction, Rounding } from '@dolomite-exchange/v2-sdk'
import AllInclusiveIcon from '@material-ui/icons/AllInclusive'
import { BorrowPosition, BorrowPositionStatus, useAllBorrowPositions } from '../../types/borrowPositionData'
import { ZERO_ADDRESS, ZERO_FRACTION } from '../../constants'
import { TableLoader } from '../Loader'
import { Transfer, useTransfersByWalletAddress } from '../../types/transferData'
import { Trade, useTradeDataByWallet } from '../../types/tradeData'
import Modal from '../Modal'
import toDate from '../../utils/toDate'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import getLogoOrDefault from '../common/TokenLogos'
import { ExternalLink } from '../../theme'
import { getEtherscanLink } from '../../utils'
import { StyledTooltip, StyledTooltipWithIcon } from '../common/StyledTooltip'
import { formatAmount } from '../../utils/formatAmount'
import AmountWithFullTooltip from '../AmountWithFullTooltip'
import {
  STRATEGY_INDEX_LOWER,
  STRATEGY_INDEX_UPPER,
  StrategyWithAmounts,
  useStrategyData,
} from '../../pages/Strategies/StrategiesList'
import { useHistory } from 'react-router-dom'
import useNetInterestRate, { useStrategyInterestRate } from '../../hooks/useNetInterestRate'
import {
  AvgYieldTitle,
  AvgYieldValue,
  BasicStats,
  ClaimButton,
  ClaimLink,
  CurrentYield,
  CurrentYieldTitle,
  EquityStats,
  EquityTitle,
  EquityValue,
  EquityValueUsd,
  ExternalRewardsHeader,
  ExternalRewardsRow,
  ExternalRewardsSubtitle,
  ExternalRewardsTitle,
  ExternalRewardsWrapper,
  getInterestRateCategoryString,
  HealthFactor,
  InfinityWrapper,
  InterestRateLeft,
  InterestRateRight,
  InterestRateStats,
  NameColumn,
  StatRow,
  StrategyStats,
  TypeColumn,
  YieldColumn,
} from '../../pages/Strategies/ManageStrategyModal'
import { SubmitButton } from '../../pages/Strategies'
import { PositionHealthTooltip } from '../../pages/Borrow/BorrowPositionRow'
import { Percent } from '@dolomite-exchange/sdk-core'
import { useShowYieldAsApr } from '../../state/user/hooks'
import { useHistoricalInterestRateTimestamp, useInterestRateData } from '../../types/interestRateData'
import { useFiatPricesWithLoadingIndicator } from '../../hooks/useFiatValue'
import { InterestRatePartCategory } from '../../types/InterestRatePart'
import calculateAprToApy from '../../utils/calculateAprToApy'
import { RefreshFrequency } from '../../state/chain/hooks'
import JSBI from 'jsbi'
import { PageSizeContext } from '../../pages/App'

const BorrowWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const TokenLogo = styled.div<{ large?: boolean }>`
  display: inline-block;
  margin-right: 3px;
  margin-top: ${({ large }) => (large ? '2px' : '0')};
  vertical-align: top;
  width: ${({ large }) => (large ? '18px' : '14px')};

  img {
    width: 100%;
  }

  @media screen and (max-width: 515px) {
    display: none;
  }
`

const LogoHelper = styled.span`
  display: inline-block;
  height: 100%;
  vertical-align: middle;
`

const LoaderWrapper = styled.div`
  width: 100%;
  padding: 0 25px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0 20px;
  `};
`

const ModalRows = styled.div`
  width: 100%;
  font-size: 13px;
  padding-top: 5px;
  @media screen and (max-width: 515px) {
    font-size: 11px;
  }
  @media screen and (max-width: 515px) {
    font-size: 10px;
  }
`

const ModalRow = styled.div`
  width: 100%;
  height: 25px;
  margin-bottom: 10px;
`

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.5rem 2rem;
`

const DollarValue = styled.span`
  color: ${({ theme }) => theme.text3};
`

const SubmitButtonWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  display: inline-block;
  vertical-align: bottom;
  text-align: right;
  pointer-events: all;
`

const getPositionHealth = (health: Fraction | undefined) => {
  return health
    ? health.greaterThan(new Fraction('125', '100'))
      ? 3
      : health.greaterThanOrEqual(new Fraction('115', '100'))
      ? 2
      : 1
    : 3
}

interface StrategyModalProps {
  position: BorrowPosition
  interestRate: Percent | undefined
  historicalInterestRate: Percent | undefined
  positionEquity: Fraction
  positionEquityUsd: Fraction
  strategy: StrategyWithAmounts
  strategyLink: string
  transfers: Transfer[]
  unfilteredTransfers: Transfer[]
  trades: Trade[]
  open: boolean
  close: () => void
  isMobile: boolean
  isTablet: boolean
}

function StrategyModalComparator(prevProps: StrategyModalProps, nextProps: StrategyModalProps) {
  return (
    prevProps.strategy === nextProps.strategy &&
    prevProps.open === nextProps.open &&
    prevProps.transfers.length === nextProps.transfers.length
  )
}

const StrategyModal = React.memo(function StrategyModalInner({
  position,
  interestRate,
  historicalInterestRate,
  positionEquity,
  positionEquityUsd,
  strategy,
  strategyLink,
  transfers,
  unfilteredTransfers,
  open,
  close,
}: StrategyModalProps) {
  const { t } = useTranslation()
  const [showYieldAsApr] = useShowYieldAsApr()
  const history = useHistory()
  const { data: assetInterestRateMap } = useInterestRateData(strategy.chain)
  const collateralInterestRateParts =
    assetInterestRateMap[strategy.collateralAssets[0].address]?.outsideSupplyInterestRateParts
  const filteredTransfers = useMemo(() => {
    return transfers.filter((_, index) => index !== 1)
  }, [transfers])
  const positionHealthFactor = useMemo(() => {
    return getPositionHealth(position.positionHealth)
  }, [position.positionHealth])
  const parsedRate = useMemo(() => {
    return parseFloat(interestRate?.denominator.toString() ?? '0') > 0 ? formatAmount(interestRate, 2) : '0.00%'
  }, [interestRate])
  const parsedHistoricalRate = useMemo(() => {
    return parseFloat(historicalInterestRate?.denominator.toString() ?? '0') > 0
      ? formatAmount(historicalInterestRate, 2)
      : '0.00%'
  }, [historicalInterestRate])
  return (
    <Modal isOpen={open} onDismiss={close} maxHeight={200} maxWidthPx={480}>
      <ModalContent>
        <StrategyStats>
          <EquityStats>
            <EquityTitle>
              Your Equity{' '}
              <StyledTooltipWithIcon
                tooltipText={`This is the approximate amount that you will receive after closing this strategy.`}
              />
            </EquityTitle>
            <EquityValue>
              {formatAmount(positionEquity, undefined, true, '-', false)}{' '}
              <span>{cleanCurrencySymbol(position.supplyAmounts[0]?.token)}</span>
            </EquityValue>
            <EquityValueUsd>{formatAmount(positionEquityUsd, 2, true, '-', true)}</EquityValueUsd>
          </EquityStats>
          <BasicStats>
            <StatRow>
              Health:{' '}
              <HealthFactor health={positionHealthFactor}>
                <StyledTooltip
                  title={
                    <PositionHealthTooltip
                      positionHealth={
                        position.positionHealth
                          ? parseFloat(position.positionHealth.toFixed(2, undefined, Rounding.ROUND_DOWN))
                          : undefined
                      }
                      positionHealthFactor={positionHealthFactor}
                      t={t}
                      isNew={false}
                    />
                  }
                  placement='top'
                  arrow={true}
                >
                  <span>
                    {!position.positionHealth ? (
                      <InfinityWrapper expanded={true}>
                        <AllInclusiveIcon />
                      </InfinityWrapper>
                    ) : position.positionHealth.greaterThan('10') ? (
                      '>10'
                    ) : (
                      position.positionHealth.toFixed(2, undefined, Rounding.ROUND_DOWN)
                    )}
                  </span>
                </StyledTooltip>
              </HealthFactor>
            </StatRow>
            <StatRow>
              Opened:{' '}
              <StyledTooltip
                title={`${toDate(position.openTimestamp, true)}`}
                placement={'right'}
                style={{ cursor: 'pointer' }}
              >
                <>{toDate(position.openTimestamp)}</>
              </StyledTooltip>
            </StatRow>
            {position.expirationTimestamp && (
              <StatRow>
                Expiration:{' '}
                <StyledTooltip
                  title={`${toDate(position.openTimestamp, true)}`}
                  placement={'right'}
                  style={{ cursor: 'pointer' }}
                >
                  <>{toDate(position.expirationTimestamp)}</>
                </StyledTooltip>
              </StatRow>
            )}
          </BasicStats>
        </StrategyStats>
        <InterestRateStats>
          <InterestRateLeft>
            <CurrentYieldTitle>Current Yield</CurrentYieldTitle>
            <CurrentYield>
              {parsedRate} <span>{showYieldAsApr ? 'APR' : 'APY'}</span>
            </CurrentYield>
          </InterestRateLeft>
          <InterestRateRight>
            <AvgYieldTitle>30 Day Avg.</AvgYieldTitle>
            <AvgYieldValue>
              {parsedHistoricalRate} <span>{showYieldAsApr ? 'APR' : 'APY'}</span>
            </AvgYieldValue>
          </InterestRateRight>
        </InterestRateStats>
        {collateralInterestRateParts &&
          collateralInterestRateParts.filter(part => part.category !== InterestRatePartCategory.NATIVE_YIELD).length >
            0 && (
            <ExternalRewardsWrapper>
              <ExternalRewardsTitle>Other Rewards</ExternalRewardsTitle>
              <ExternalRewardsSubtitle>
                This strategy earns rewards from sources outside of Dolomite.
              </ExternalRewardsSubtitle>
              <ExternalRewardsHeader>
                <NameColumn>Name</NameColumn>
                <TypeColumn>Type</TypeColumn>
                <YieldColumn>{showYieldAsApr ? 'APR' : 'APY'}</YieldColumn>
                <ClaimLink></ClaimLink>
              </ExternalRewardsHeader>
              {collateralInterestRateParts
                .filter(part => part.category !== InterestRatePartCategory.NATIVE_YIELD)
                .map((part, i) => (
                  <ExternalRewardsRow key={i}>
                    <NameColumn>
                      {part.metadata
                        ? `+${part.metadata.units * strategy.leverage} ${part.metadata.unitsLabel}`
                        : part.label}
                    </NameColumn>
                    <TypeColumn>{getInterestRateCategoryString(part.category)}</TypeColumn>
                    <YieldColumn>
                      {part.interestRate
                        ? showYieldAsApr
                          ? calculateAprToApy(part.interestRate).toFixed(2)
                          : part.interestRate.toFixed(2)
                        : '-'}
                      {part.interestRate && '%'}
                    </YieldColumn>
                    <ClaimLink>{part.rewardClaimUrl && <ClaimButton>Claim</ClaimButton>}</ClaimLink>
                  </ExternalRewardsRow>
                ))}
            </ExternalRewardsWrapper>
          )}
        <ColumnTitles modal>
          <Column width={25} textAlign={'left'}>
            {t('action')}
          </Column>
          <Column width={55} textAlign={'left'}>
            {t('amount')}
          </Column>
          <Column width={20} textAlign={'right'}>
            {t('date')}
          </Column>
        </ColumnTitles>
        <ModalRows>
          {filteredTransfers.map((action, i) => (
            <ModalRow key={`transfer-action-${i}`}>
              <Column width={25} textAlign={'left'}>
                <ExternalLink
                  href={getEtherscanLink(strategy.chain, action.transaction.transactionHash, 'transaction')}
                  style={{ fontWeight: 400 }}
                >
                  {i === 0 ? t('open') : t('close')}
                </ExternalLink>
              </Column>
              <Column width={55} textAlign={'left'}>
                <TokenLogo>
                  <LogoHelper>
                    <img
                      src={getLogoOrDefault(cleanCurrencySymbol(action.amount.currency) ?? '')}
                      alt={`${cleanCurrencySymbol(action.amount.currency)} logo`}
                    />
                  </LogoHelper>
                </TokenLogo>
                <AmountWithFullTooltip amount={action.amount} />{' '}
                <DollarValue>({formatAmount(action.amountUSD, 2, true, '$0.00', true)})</DollarValue>
              </Column>
              <StyledTooltip
                title={`${toDate(action.transaction.timestamp, true)}`}
                placement={'right'}
                style={{ cursor: 'pointer' }}
              >
                <Column width={20} textAlign={'right'}>
                  {toDate(action.transaction.timestamp).replace('a few seconds ago', '< 1 min ago')}
                </Column>
              </StyledTooltip>
            </ModalRow>
          ))}
        </ModalRows>
        <SubmitButtonWrapper onClick={() => history.push(strategyLink)}>
          <SubmitButton card>Manage</SubmitButton>
        </SubmitButtonWrapper>
      </ModalContent>
    </Modal>
  )
},
StrategyModalComparator)

interface StrategyRowProps {
  position: BorrowPosition
  strategy: StrategyWithAmounts
  strategyNum: number
  transfers: Transfer[]
  unfilteredTransfers: Transfer[]
  trades: Trade[]
  isMobile: boolean
  isTablet: boolean
}

function StrategyRowRowComparator(prevProps: StrategyRowProps, nextProps: StrategyRowProps) {
  return (
    prevProps.isMobile === nextProps.isMobile &&
    prevProps.isTablet === nextProps.isTablet &&
    prevProps.strategyNum === nextProps.strategyNum &&
    prevProps.position.status === nextProps.position.status &&
    prevProps.position.totalBorrowUSD === nextProps.position.totalBorrowUSD &&
    prevProps.position.totalSupplyUSD === nextProps.position.totalSupplyUSD
  )
}

const StrategyRow = React.memo(function StrategyRowInner({
  position,
  strategy,
  strategyNum,
  transfers,
  unfilteredTransfers,
  trades,
  isMobile,
  isTablet,
}: StrategyRowProps) {
  const { chainId } = useActiveWeb3React()
  const [modalOpen, setModalOpen] = useState(false)
  const closeModal = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  const isOpen = position.status === BorrowPositionStatus.Open
  const [positionEquity, positionEquityUsd] = useMemo(() => {
    const positionEquity = isOpen
      ? position.supplyAmounts[0]?.amountTokenWei.asFraction.subtract(
          position.totalBorrowUSD.divide(
            position.totalSupplyUSD.divide(position.supplyAmounts[0].amountTokenWei.asFraction),
          ),
        ) ?? ZERO_FRACTION
      : transfers[0].amount.asFraction
    const positionEquityUsd = isOpen
      ? position.totalSupplyUSD.subtract(position.totalBorrowUSD) ?? ZERO_FRACTION
      : transfers[0].amountUSD ?? ZERO_FRACTION
    return [positionEquity, positionEquityUsd]
  }, [position.supplyAmounts, position.totalBorrowUSD, position.totalSupplyUSD, transfers, isOpen])
  const ratesAtOpen = useHistoricalInterestRateTimestamp(
    position?.supplyAmounts[0]?.token,
    position?.openTimestamp,
    chainId,
  )
  const pendleFixedRate = ratesAtOpen?.find(rate => rate.label === 'Pendle Fixed APR')?.supplyInterestRate
  const interestRate = useNetInterestRate(position, pendleFixedRate)
  const { data: interestRateMap } = useInterestRateData(strategy.chain)
  const [fiatPriceMap] = useFiatPricesWithLoadingIndicator(undefined, strategy.chain, RefreshFrequency.Medium)
  const historicalInterestRate = useStrategyInterestRate(
    strategy.supplyAmounts,
    strategy.borrowAmounts,
    fiatPriceMap,
    interestRateMap,
    true,
    true,
    undefined,
    undefined,
    undefined,
    pendleFixedRate,
  )
  const initialDeposit = transfers[0]
  const closePosition = transfers[2]
  const profit = (position.status === BorrowPositionStatus.Open
    ? positionEquity //position.supplyAmounts[0]?.amountTokenWei
    : closePosition.amount
  ).asFraction.subtract(initialDeposit.amount.asFraction)
  // const profitApr = useMemo(() => {
  //   const currentDate = new Date()
  //   const initialDepositDate = new Date(initialDeposit.transaction.timestamp)
  //   const daysBetween = createFraction(
  //     ((currentDate.getTime() - initialDepositDate.getTime()) / (1000 * 60 * 60 * 24)).toFixed(16),
  //   )
  //   if (!daysBetween || daysBetween.equalTo(ZERO_FRACTION)) return undefined
  //   return profit
  //     .divide(initialDeposit.amountUSD.asFraction)
  //     .multiply(365)
  //     .divide(daysBetween)
  //     .multiply(100)
  // }, [profit, initialDeposit])
  const equity = isOpen ? position.supplyAmounts[0].amountTokenWei.asFraction : transfers[0].amount.asFraction
  const projectedProfit = profit ? profit : ZERO_FRACTION
  // const projectedProfitUSD = profitApr ? initialDeposit.amountUSD.asFraction.multiply(profitApr) : ZERO_FRACTION
  // const profit = positionEquityUsd.asFraction.subtract(initialDeposit.amountUSD.asFraction)
  // const profitApr = useMemo(() => {
  //   const currentDate = new Date()
  //   const initialDepositDate = new Date(initialDeposit.transaction.timestamp)
  //   const daysBetween = createFraction(
  //     ((currentDate.getTime() - initialDepositDate.getTime()) / (1000 * 60 * 60 * 24)).toFixed(16),
  //   )
  //   if (!daysBetween || daysBetween.equalTo(ZERO_FRACTION)) return undefined
  //   return profit
  //     .divide(initialDeposit.amountUSD.asFraction)
  //     .multiply(365)
  //     .divide(daysBetween)
  //     .multiply(100)
  // }, [profit, initialDeposit])
  // const projectedProfit = profitApr ? initialDeposit.amount.asFraction.multiply(profitApr) : ZERO_FRACTION
  // const projectedProfitUSD = profitApr ? initialDeposit.amountUSD.asFraction.multiply(profitApr) : ZERO_FRACTION
  // const equityText = `${formatAmount(positionEquity)} ${position.effectiveSupplyTokens[0].symbol}
  // (${formatAmount(positionEquityUsd, 2, true, '', true)})`
  // const profitText = `${formatAmount(projectedProfit)} ${position.effectiveSupplyTokens[0].symbol}
  // (${formatAmount(projectedProfitUSD, 2, true, '', true)})`
  const strategyLink = `/strategies/${strategyNum}/${position.id}`
  return (
    <div onClick={() => setModalOpen(true)}>
      <DataRow
        columns={[
          {
            width: 10,
            mobileWidth: 30,
            textAlign: 'left',
            type: 'action',
            data: position.status.substring(0, 1) + position.status.substring(1).toLowerCase(),
            date: isMobile && !isTablet ? position.openTimestamp : undefined,
          },
          {
            width: 28,
            mobileWidth: 30,
            textAlign: 'left',
            type: 'textLower',
            data: `${strategy.title} ${strategy.leverage}x`,
          },
          {
            width: 24,
            mobileWidth: 35,
            textAlign: 'left',
            type: 'amount',
            data: positionEquity,
            token: strategy.collateralAssets[0],
          },
          {
            width: 24,
            mobileWidth: 35,
            textAlign: isMobile && !isTablet ? 'right' : 'left',
            type: 'amount',
            data: projectedProfit,
            token: strategy.collateralAssets[0],
          },
          {
            width: 14,
            textAlign: 'right',
            type: 'text',
            data: `${toDate(position.openTimestamp)}, ${(isOpen
              ? position.openTimestamp
              : position.closeTimestamp
            )?.toLocaleString([], {
              hour: 'numeric',
              minute: '2-digit',
            })}`,
            hideOnMobile: true,
          },
        ]}
        contentHeight={36}
        history
      />
      {modalOpen && (
        <StrategyModal
          position={position}
          interestRate={interestRate}
          historicalInterestRate={historicalInterestRate}
          positionEquity={positionEquity}
          positionEquityUsd={positionEquityUsd}
          strategy={strategy}
          strategyLink={strategyLink}
          transfers={transfers}
          unfilteredTransfers={unfilteredTransfers}
          trades={trades}
          open={modalOpen}
          close={closeModal}
          isMobile={isMobile}
          isTablet={isTablet}
        />
      )}
    </div>
  )
},
StrategyRowRowComparator)

interface ActivePosition {
  position: BorrowPosition
  strategy: number
  transfers: Transfer[]
  unfilteredTransfers: Transfer[]
  trades: Trade[]
}

export default function Strategies() {
  const { t } = useTranslation()
  const { account, chainId } = useActiveWeb3React()
  const { data: positions, loading: isBorrowLoading } = useAllBorrowPositions(account ?? ZERO_ADDRESS)
  const [isLoading, setIsLoading] = useState(true)
  const { data: allTransfers } = useTransfersByWalletAddress(account)
  const { data: allTrades } = useTradeDataByWallet(account)

  const strategies = useMemo(() => {
    return positions.filter(position => {
      const positionId = parseFloat(position?.id.split('-')[1])
      return positionId >= STRATEGY_INDEX_LOWER && positionId <= STRATEGY_INDEX_UPPER
    })
  }, [positions])
  const strategiesWithAmounts = useStrategyData()
  const positionIds = useMemo(() => new Set(strategies.map(position => position.id)), [strategies])
  const filteredTransfers = useMemo(() => {
    return allTransfers?.filter(transfer => {
      return positionIds.has(transfer.toMarginAccount.toString())
    })
  }, [allTransfers, positionIds])
  const filteredTrades = useMemo(() => {
    return allTrades?.filter(trade => {
      return trade.makerAccount && positionIds.has(trade.makerAccount.toString())
    })
  }, [allTrades, positionIds])
  const activePositions = useMemo(() => {
    return strategies?.reduce((filteredPositions: ActivePosition[], position: BorrowPosition) => {
      const positionTransfers = filteredTransfers
        ?.filter(transfer => transfer.toMarginAccount.toString() === position.id)
        .sort((a, b) =>
          position.specialInfo.isolationModeVaultAddress
            ? JSBI.toNumber(a.serialId) - JSBI.toNumber(b.serialId)
            : JSBI.toNumber(b.serialId) - JSBI.toNumber(a.serialId),
        )
      const unfilteredTransfers = allTransfers?.filter(transfer => {
        return (
          position.id === transfer.toMarginAccount.toString() || position.id === transfer.fromMarginAccount.toString()
        )
      })
      const positionTrades = filteredTrades?.filter(trade => trade.makerAccount?.toString() === position.id)
      if (positionTransfers.length < 2) {
        //|| positionTransfers[0].token.symbol !== positionTransfers[1].token.symbol
        return filteredPositions
      }
      const initialDeposit = positionTransfers[0]
      const loop = positionTransfers[1]
      // Divides the initial deposit + looped amount by initial deposit to get a leverage ratio, then rounds to the
      // nearest whole number to determine which strategy it matches
      const calculatedLeverage = Math.round(
        parseFloat(
          initialDeposit.amount.asFraction
            .add(loop.amount.asFraction)
            .divide(initialDeposit.amount.asFraction)
            .toFixed(2),
        ),
      )
      const leverage = calculatedLeverage === 1 ? 2 : calculatedLeverage
      const matchedStrategy = strategiesWithAmounts.find(
        strategy =>
          strategy.leverage === leverage &&
          strategy.chain === chainId &&
          strategy.collateralAssets[0].address.toUpperCase() === position.effectiveSupplyTokens[0]?.id.toUpperCase() &&
          strategy.debtAssets[0].address.toUpperCase() === position.effectiveBorrowTokens[0]?.id.toUpperCase(),
      )
      if (matchedStrategy) {
        filteredPositions.push({
          position: position,
          strategy: parseInt(matchedStrategy.id) - 1,
          transfers: positionTransfers,
          unfilteredTransfers: unfilteredTransfers,
          trades: positionTrades,
        })
      }
      return filteredPositions
    }, [])
  }, [strategies, filteredTransfers, allTransfers, filteredTrades, strategiesWithAmounts, chainId])
  /*const activePositions = useMemo(() => {
    return strategies?.reduce((filteredPositions: ActivePosition[], position: BorrowPosition) => {
      const positionTransfers = filteredTransfers?.filter(
        transfer => transfer.toMarginAccount.toString() === position.id,
      )
      const positionTrades = filteredTrades?.filter(trade => trade.makerAccount?.toString() === position.id)
      if (positionTransfers.length < 2) {
        return filteredPositions
      }
      const initialDeposit = positionTransfers[0]
      const loop = positionTransfers[1]
      // Divides the initial deposit + looped amount by initial deposit to get a leverage ratio, then rounds to the nearest whole number to determine which strategy it matches
      const calculatedLeverage = Math.round(
        parseFloat(
          initialDeposit.amount.asFraction
            .add(loop.amount.asFraction)
            .divide(initialDeposit.amount.asFraction)
            .toFixed(2),
        ),
      )
      const leverage = calculatedLeverage === 1 ? 2 : calculatedLeverage
      const matchedStrategy = strategiesWithAmounts.find(
        strategy =>
          strategy.leverage === leverage &&
          strategy.chain === chainId &&
          strategy.collateralAssets[0].address.toLowerCase() === position.effectiveSupplyTokens[0]?.id.toLowerCase() &&
          strategy.debtAssets[0].address.toLowerCase() === position.effectiveBorrowTokens[0]?.id.toLowerCase(),
      )
      if (matchedStrategy) {
        filteredPositions.push({
          position: position,
          strategy: parseInt(matchedStrategy.id) - 1,
          transfers: positionTransfers,
          trades: positionTrades,
        })
      }
      return filteredPositions
    }, [])
  }, [chainId, strategies, filteredTrades, filteredTransfers, strategiesWithAmounts])*/

  useEffect(() => {
    isLoading && !isBorrowLoading && setIsLoading(false)
  }, [isBorrowLoading, isLoading])

  const widths = {
    widths: [10, 15, 15, 15],
    starts: [0, 20, 50, 85],
    tabletWidths: [10, 15, 15, 15],
    tabletStarts: [0, 20, 50, 85],
    mobileWidths: [15, 20, 20],
    mobileStarts: [0, 30, 80],
  }

  const { x, y } = useContext(PageSizeContext)
  const isMobile = x < 960
  const isTablet = 660 < x && x < 960

  return (
    <BorrowWrapper>
      <ColumnTitles>
        <Column width={10} mobileWidth={30} textAlign={'left'}>
          {t('open')}
        </Column>
        <Column width={28} mobileWidth={30} textAlign={'left'}>
          {t('strategy')}
        </Column>
        <Column width={24} mobileWidth={35} textAlign={'left'}>
          {t('equity')}
        </Column>
        <Column width={24} mobileWidth={35} textAlign={isMobile && !isTablet ? 'right' : 'left'}>
          {t('profit')}
        </Column>
        <Column width={14} mobileWidth={20} hideOnMobile={true} textAlign={'right'}>
          {t('openTime')}
        </Column>
      </ColumnTitles>
      <DataRows>
        {account ? (
          isLoading ? (
            <LoaderWrapper>
              <TableLoader
                rows={isMobile ? 5 : isTablet ? 4 : 3}
                height={30}
                spacing={55}
                marginTop={20}
                isMobile={isMobile}
                isTablet={isTablet}
                widths={widths}
              />
            </LoaderWrapper>
          ) : positions.length === 0 ? (
            <NoTrades>{t('noBorrows')}</NoTrades>
          ) : (
            activePositions.map(position => (
              <StrategyRow
                key={position.position.id}
                position={position.position}
                strategy={strategiesWithAmounts[position.strategy]}
                strategyNum={position.strategy}
                transfers={position.transfers}
                unfilteredTransfers={position.unfilteredTransfers}
                trades={position.trades}
                isMobile={isMobile}
                isTablet={isTablet}
              />
            ))
          )
        ) : (
          <ConnectWalletButton>
            <ConnectBtn>
              <Web3Status />
            </ConnectBtn>
          </ConnectWalletButton>
        )}
      </DataRows>
    </BorrowWrapper>
  )
}
